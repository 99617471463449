<section class="blogMargins" [ngStyle.lt-md]="{'width':'70%'}">
  <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom:20px">
    <div>
      <img src="../../../../../../assets/images/logoOnly_green.png" style="width:20px"/>
    </div>
    <div class="mat-title" style="margin-bottom:0px; margin-left:5px">brBlog</div>
  </div>
  <h1 class="mat-display-1" style="margin-bottom:20px">Planning for Long-Term Growth in E-commerce</h1>
  <div style="margin-bottom:20px"><span style="font-weight:450">by <a href="https://www.linkedin.com/in/allenedgarrogers" target="_blank">ALLEN E. ROGERS</a></span> - October 12, 2020</div>
  <div class="blogBody">
    <p>
      If you have a product to sell and an internet connection, you can launch an online store. Imagine your successful e-commerce business—generating income in a rapid-growth industry and making it possible to build a flexible, virtual working life.
    </p>
    <p>
      Once you hit the market, you can take advantage of any number of seller-focused tools, products, and services to standout and accelerate your company's growth. The marketplace benefits from a wide range of product options which improve user and consumer experiences, so there will be plenty of competition.
    </p>
    <p>
      Amidst the bustle, an ambitious, focused plan for driving your company’s success and sustainable growth is essential. Here are three practical tips to help you position your e-commerce business for long-term success.
    </p>
    <div>
      <div class="mat-headline" style="margin-bottom:5px">1. <a href="https://www.shopify.com/blog/how-to-build-a-brand" target="_blank">Own Your Brand</a></div>
    </div>
    <p>
      Whenever, wherever possible, establish a brand for the products you bring to market. Branding is essential for market differentiation and to protect against product saturation and listing hijacking. On marketplaces like Amazon — and more recently Wal-Mart — multiple sellers may list offers for the same product. On universally known products like ballpoint pens, this practice is mostly harmless. Marketplace algorithms dictate whether the seller's offer gets visibility and thus a chance for purchase. But if you have the capacity to create differentiation with your own line of goods, it's worth the investment.
    </p>
    <p>
      If you list a product on a marketplace without being the brand owner, you are subject to the hierarchical ranking of the listing data. One of three factors will determine how and where your product displays:
    </p>
    <div>
      <ul>
        <li>The marketplace itself making a competing offer.</li>
        <li>The manufacturer or brand owner of the product (if they participate on that marketplace).</li>
        <li>The highest-ranked contributions of a collection of sellers who have been selling the longest and have the most traction.</li>
      </ul>
    </div>
    <p>
      Even if you are a value-added reseller who sells products through distribution or alternative manufacturers, consider crafting a small set of private label items to highlight who you are and what your company is about. You can still focus on adding previously unlisted products to a marketplace to differentiate yourself, but your own labels and branding go a long way.
    </p>
    <p>
      When you build a brand, gaining traction takes time. You have options for accelerating early growth ― like hiring a digital marketing agency or implementing time tested <a href="https://www.entrepreneur.com/article/310427" target="_blank">brand awareness strategies</a> ― but make sure you build the appropriate foundation and infrastructure to help your business thrive before overspending on outreach.
    </p>
    <div>
      <div class="mat-headline" style="margin-bottom:5px">2. <a href="https://www.dummies.com/business/start-a-business/business-plans/how-to-build-a-strong-foundation-for-your-small-business/" target="_blank">Build a Strong Business Foundation</a></div>
    </div>
    <p>
      Whether in business or the start-up industry, there is a pressure to run at full speed from day one. As a newcomer, don't you need to strike while the iron is hot, getting products to market as quickly as possible? For established companies looking to shift toward e-commerce, you may feel like you need to pivot or migrate quickly or you'll be left In the dust. In both cases, my advice is the same.
    </p>
    <p>
      Take a breath.
    </p>
    <p>
      As you launch a new business, you are bound to encounter unexpected hurdles before you ever get to market. Maybe it is a license you didn't know you needed or an unexpected slowdown in the supply chain. For a veteran organization, your ingrained operating procedures should sustain you as you transition — as long as you pay attention to the correct signals. The fact you are even working to expand or migrate channels speaks to your ability to adapt and will serve you well over time.
    </p>
    <p>
      Don't forget: skill sets evolve, but business fundamentals persevere.
    </p> 
    <p>
      The path to market and mechanisms to get there may change, but the product the customer receives should be the same. Every product you offer should adhere to these three basic principles:
    </p>
    <div>
      <ul>
        <li>Be easy to procure.</li>
        <li>Provide a quality customer experience.</li>
        <li>Have an identifiable target audience and the means to acquire it.</li>
      </ul>
    </div>
    <p>
      Here's one example: the <a href="https://www.emarketer.com/content/us-ecommerce-will-rise-18-2020-amid-pandemic" target="_blank">demand for contactless shopping and delivery</a> in the COVID-19 era has skipped industry growth ahead by nearly a decade in a matter of months. With the above fundamentals in place, adjusting delivery and shipping mechanisms should not require a complete rehaul of your business model.
    </p>
    <p>
      Establishing the infrastructure and process to provide an excellent customer experience will set you apart and put you on a path to build incrementally, then exponentially.
    </p>
    <div>
      <div class="mat-headline" style="margin-bottom:5px">3. <a href="https://coschedule.com/marketing-strategy/marketing-persona/" target="_blank">Find Your Target Market</a></div>
    </div>
    <p>
      In 2012, after sharing a business plan and pitch with an advisor, he asked me one critical question, "Who is your target customer?" To his chagrin, my answer was, "Well…anyone."
    </p>
    <p>
      As you can imagine, that was not the correct response.
    </p>
    <p>
      It is tempting to dream about creating the perfect product or service, one that is available and applicable to everyone. Users who never considered being in the market for such things will spot it and come knocking on your door.
    </p>
    <p>
      Unfortunately, that's not how the marketplace works. If you are bringing a product to market, you must have a target customer in mind. Identifying that target and figuring out how to reach them is one of the biggest hurdles to gaining traction in any vertical, but it's within your grasp.
    </p>
    <p>
      The first step to identify your target market is to perform a market overview.
    </p>
    <div>
      <ul>
        <li>Identify competitive products and who sells them.</li>
        <li>Look at their marketing and advertising materials to see who they are targeting.</li>
        <li>Outline several potential customer personas and make an educated guess about how certain personas will interact with your products. Include these personas in your business planning documents.</li>
      </ul>
    </div>
    <p>
      Your market overview gives you a framework for how to position products when they launch.
    </p>
    <p>
      New business ideas are exciting, as is the possibility of generating fast capital in the growth industry of e-commerce. Whether you're developing a product or already seeing good returns, you won't regret taking the time to make sure your business fundamentals are sound. If you own your brand, build a strong foundation for your business, and know your audience, you will be well-positioned for sustainable growth.
    </p>
  </div>
</section>
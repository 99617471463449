import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { BloomRestService } from '../../../service/bloom-rest.service';
import { ContactUs } from '../../../model/general/contact-us';
import { ActivatedRoute } from '@angular/router';
import { SEOService } from 'src/app/service/seo.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  displayInputForm:string = '';
  displayCompleteMessage:string = 'none';
  
  contactForm: FormGroup;

  allSubjects: string[];
  allBusinessTypes: string[];
  allAreasOfInterest: string[];

  constructor(private bloomRestService:BloomRestService, private route:ActivatedRoute, private seoService:SEOService) { }

  ngOnInit(): void {
    this.seoService.updateSocialTags(
      'Contact bloomRail', 
      'Want to know more about what bloomRail can do for you? Please reach out!', 
      '/assets/images/logoOnly_green.png', 
      '/contact');
    
    this.seoService.updateDescription('bloomRail would love to hear from you, whether to chat about e-commerce or sustainability, or to see how we can help. Please reach out!');

    this.allSubjects = ['Inventory Forecaster - Request Demo',
      'Looking to launch an online store',
      'Current seller trying to grow',
      'Need a software solution',
      'Need professional services (integration, analysis, strategic management, B2B, cloud, etc)',
      'Interested in becoming a partner',
      'Product question/suggestion',
      'Other'];
    
    this.allBusinessTypes = ['SMB', 'Enterprise', 'Other'];
    
    this.allAreasOfInterest = ['Finding the right software tools', 'Scaling your business',
      'Becoming a partner', 'Other'];

    this.contactForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      companyName: new FormControl(''),
      emailAddress: new FormControl(null, {validators: [Validators.required, Validators.email]}),
      subject: new FormControl(null),
      messageContent: new FormControl(''),
      alreadyOnlineSeller: new FormControl(null),
      businessType: new FormControl(null),
      areaOfInterest: new FormControl(null),
    });

    this.route.paramMap.subscribe(params => {
      let paramSubject = params.get('subject');
      if(paramSubject == 'restockDemo') {
        this.contactForm.patchValue({subject:'Inventory Forecaster - Request Demo'});
        this.contactForm.get('subject').markAsTouched();  
      }
    });
  }

  getEmailAddressError() {
    let control = this.contactForm.get('emailAddress');
    if(control.hasError('required')) {
      return this.getRequiredFieldErrorMessage();
    }
    if(control.hasError('email')) {
      return 'Please enter a valid email address.';
    }
  }

  getRequiredFieldErrorMessage():string {
    return 'Required field.';
  }

  submit(): void {
    let firstName:string = this.contactForm.get('firstName').value;
    let lastName:string = this.contactForm.get('lastName').value;
    let companyName:string = this.contactForm.get('companyName').value;
    let emailAddress:string = this.contactForm.get('emailAddress').value;
    let subject: string = this.contactForm.get('subject').value;
    let messageContent:string = this.contactForm.get('messageContent').value;
    let alreadyOnlineSeller:string = this.contactForm.get('alreadyOnlineSeller').value;
    let businessType:string = this.contactForm.get('businessType').value;
    let areaOfInterest:string = this.contactForm.get('areaOfInterest').value;

    let requestData: ContactUs = {firstName:firstName, lastName:lastName, companyName:companyName, emailAddress:emailAddress, 
      subject:subject, messageContent:messageContent, alreadyOnlineSeller:alreadyOnlineSeller, businessType: businessType,
      areaOfInterest:areaOfInterest};

    this.bloomRestService.sendContactUsEmail(requestData).subscribe(data => {
      this.markAsSuccessful();
    },
    error => {
      this.handleError(this.contactForm, error);
    });
  }

  markAsSuccessful() {
    this.displayCompleteMessage = '';
    this.displayInputForm = 'none';
  }

  handleError(formGroup: FormGroup, error: HttpErrorResponse) {
    let fieldName: string = error.error.fieldName;
    if(fieldName) {
      let formControl = formGroup.get(fieldName);
      if(formControl) {
        formControl.setErrors({'server-error': error.error.message});
      }
    }
  }

}

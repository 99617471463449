import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

@Component({
  selector: 'app-post20201130',
  templateUrl: './post20201130.component.html',
  styleUrls: ['./post20201130.component.scss']
})
export class Post20201130Component implements OnInit {

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.seoService.updateSocialTags(
      'brBlog: 5 Rules for Picking High-Quality Vendors for Your E-commerce Business', 
      "Whether launching your business, growing an existing channel, or expanding to a new one, you'll need vendors that support...", 
      '/assets/images/icons/funnel.png', 
      '/blog/posts/post-20201130-5-rules-for-picking-high-quality-vendors-for-your-e-commerce-business');
  }

}

import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss']
})
export class MissionComponent implements OnInit {

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.seoService.updateSocialTags(
      'About bloomRail', 
      'Learn more about our team and why we do what we do.', 
      '/assets/images/logoOnly_green.png', 
      '/about');
    
    this.seoService.updateDescription("bloomRail was founded on a philosophy to 'Be Helpful and Do Good Work.' Learn more about our team here.");
  }

}

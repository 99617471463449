<section>
  <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-md="column" style="width:90%" class="centerDiv">
    <div style="margin-right:20px; width:60%; max-width:700px" data-aos="fade-down" data-aos-duration="1000">
      <div class="mat-display-1" style="margin-bottom:0px">Sustainable growth for your online small business</div>
    </div>
    <div style="margin:20px 20px 20px 20px" data-aos="fade-up" data-aos-duration="1000">
      <img src="../../../../../assets/images/icons/smbgear.png" style="width:300px"/>
    </div>
  </div>
</section>
<section>
  <div fxLayout="row" fxLayoutAlign="space-evenly top" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center" class="centerDiv container">
    <mat-card fxLayout="column" fxLayoutAlign="start center" style="width:40%; margin-top:5px; margin-bottom:5px"
      [ngStyle.lt-md]="{'width':'80%'}" data-aos="fade-right" data-aos-duration="500">
      <div class="mat-headline centerText" style="margin-bottom:0px">
        bloomRail
      </div>
      <div class="mat-headline centerText">
        Launch Package
      </div>
      <div>
        <p>New to e-commerce? Launching a new channel? Need help getting things off the ground?</p>
        <p>The bloomRail Launch Package will give you all the tools you need to get your e-commerce 
          business up and running and on track for long-term success.
        </p>
        <ul>
          <li>Market Analysis</li>
          <li>Product Creation</li>
          <li>Launch Readiness</li>
          <li>Ongoing Support</li>
        </ul>
      </div>
      <div fxFlex></div>
      <div class="centerText" style="margin-top:10px">
        <button mat-raised-button routerLink="/pricing" fragment="solutions">Learn More</button>
      </div>
    </mat-card>  
    <mat-card fxLayout="column" fxLayoutAlign="start center" style="width:40%; margin-top:5px; margin-bottom:5px"
      [ngStyle.lt-md]="{'width':'80%'}" data-aos="fade-left" data-aos-duration="500">
      <div class="mat-headline centerText" style="margin-bottom:0px">
        bloomRail
      </div>
      <div class="mat-headline centerText">
        Growth Package
      </div>
      <div>
        <p>Running a successful e-commerce business but looking to enhance your brand and optimize operations?</p>
        <p>bloomRail implements proven strategies that help businesses successfully streamline the present and scale for the future.
        </p>
        <ul>
          <li>Enhanced Brand Content</li>
          <li>Storefront Development</li>
          <li>Fraud Mitigation</li>
          <li>Professional Account Management</li>
        </ul>
      </div>
      <div fxFlex></div>
      <div class="centerText" style="margin-top:10px">
        <button mat-raised-button routerLink="/pricing" fragment="solutions">Learn More</button>
      </div>
    </mat-card>
  </div>
</section>
<section class="backgroundcolor-sky" style="margin-top:40px; padding-top:20px; padding-bottom:20px" data-aos="flip-up" data-aos-duration="1000">
  <div class="centerDiv" style="width:50%" [ngStyle.lt-md]="{'width':'90%'}">
    <div class="brandFont centerText" style="font-size:20px">Supported Channels</div>
    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-top:20px">
      <img src="../../../../assets/images/services/logoAmazon.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
      <img src="../../../../assets/images/services/logoShopify.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
      <img src="../../../../assets/images/services/logoWalmart.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
      <img src="../../../../assets/images/services/logoWoo.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
      <img src="../../../../assets/images/services/logoEbay.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
    </div>
  </div>
</section>
<section style="margin-top:40px">
  <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-md="column" style="width:90%" class="centerDiv">
    <div style="max-width:500px; margin:20px 20px 20px 20px" data-aos="fade-right" data-aos-duration="1000">
      <div class="mat-headline">
        Set up your online small business for success in e-commerce
      </div>
      <div>
        <ul>
          <li>Low-inventory alerts</li>
          <li>Know when to reorder</li>
          <li>Keep your top products in-stock</li>
          <li>Track inbound inventory</li>
          <li>Never miss a sale!</li>
        </ul>
      </div>
      <div class="centerText">
        <a mat-raised-button routerLink="/product-info/inventory-forecaster">Try our <span style="font-weight:bold">Inventory Forecaster</span></a>
        <div class="mat-caption" style="margin-top:5px">30-day free trial</div>
      </div>
    </div>
    <div style="margin:20px 20px 20px 20px" data-aos="flip-up" data-aos-duration="1000">
      <img src="../../../../../assets/images/icons/restock.png" style="width:300px"/> 
    </div>
  </div>
</section>
<section style="margin-top:40px; margin-bottom:20px">
  <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-md="column" style="width:90%" class="centerDiv">
    <div style="margin:20px 20px 20px 20px" data-aos="flip-down" data-aos-duration="1000">
      <img src="../../../../../assets/images/icons/twohouses.png" style="width:300px"/>
    </div>
    <div style="max-width:500px; margin:20px 20px 20px 20px" data-aos="fade-left" data-aos-duration="1000">
      <div class="mat-headline">
        Expand your business' scope with B2B
      </div>
      <div style="margin:0px 10px 20px 0px">
        bloomRail are experts in business-to-business. Expand your customer-base with B2B-centric features
        and targeting that open you up to 10x the purchases of the traditional B2C market.
      </div>
      <div class="centerText">
        <a mat-raised-button routerLink="/services/b2b">Learn More</a>
      </div>
    </div>
  </div>
</section>
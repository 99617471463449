<section>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayout.lt-md="column" style="width:70%" class="centerDiv">
    <div style="width:60%; max-width:700px" data-aos="fade-down" data-aos-duration="1000">
      <div class="mat-display-1" style="margin-bottom:0px">Efficient, Scalable, Profitable.</div>
    </div>
    <div style="margin:20px 20px 20px 20px" data-aos="fade-up" data-aos-duration="1000">
      <img src="../../../../../assets/images/icons/container.png" style="width:300px"/>    
    </div>
  </div>
</section>
<section class="centerDiv" style="width:50%">
  <div class="mat-headline centerText">
    Enterprise Solutions
  </div>
  <div>
    <p>
      Our team has years of expertise running e-commerce projects at the enterprise level for the largest, most complex organizations in the world, including Amazon and the US government.
    </p>
    <p>
      We know what it takes to build custom software and solutions that drive profits, take new channels to market, identify opportunities, and develop your business with long-term, sustainable success.
    </p>
    <p>
      Contact us today for a free consultation and learn how our team can help your organization develop, launch, and scale its business.
    </p>
  </div>
  <div class="centerText" style="margin-top:20px">
    <a mat-raised-button routerLink="/contact">Contact Us</a>
  </div>
</section>
<section class="backgroundcolor-sky" style="margin-top:40px; padding-top:20px; padding-bottom:20px" data-aos="flip-up" data-aos-duration="1000">
  <div class="centerDiv brandFont" style="width:50%">
    <div fxLayout="row" fxLayoutAlign="space-evenly center">
      <div class="techFeatureSpacing">Systems Integration</div>
      <div class="techFeatureSpacing">Multi-channel Support</div>
      <div class="techFeatureSpacing">Advanced Analytics</div>
    </div>
  </div>
</section>
<section style="margin-top:40px">
  <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-md="column" style="width:90%" class="centerDiv">
    <div style="max-width:500px; margin:20px 20px 20px 20px" data-aos="fade-right" data-aos-duration="1000">
      <div class="mat-headline">
        Efficiently track and forecast your inventory
      </div>
      <div>
        <ul>
          <li>Accurately track your turn rate</li>
          <li>Know precisely when to reorder</li>
          <li>Keep your most profitable items in-stock</li>
          <li>Never miss a sale</li>
          <li>Turn the growth flywheel</li>
        </ul>
      </div>
      <div class="centerText">
        <a mat-raised-button routerLink="/product-info/inventory-forecaster">Try our <span style="font-weight:bold">Inventory Forecaster</span></a>
        <div class="mat-caption" style="margin-top:5px">30-day free trial</div>
      </div>
    </div>
    <div style="margin:20px 20px 20px 20px" data-aos="flip-up" data-aos-duration="1000">
      <img src="../../../../../assets/images/icons/restock.png" style="width:300px"/>
    </div>
  </div>
</section>
<section style="margin-top:40px; margin-bottom:20px">
  <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-md="column" style="width:90%" class="centerDiv">
    <div style="margin:20px 20px 20px 20px" data-aos="flip-down" data-aos-duration="1000">
      <img src="../../../../../assets/images/icons/diagram.png" style="width:300px"/>
    </div>
    <div style="max-width:500px; margin:20px 20px 20px 20px" data-aos="fade-left" data-aos-duration="1000">
      <div class="mat-headline">
        Sharpen your B2B channel
      </div>
      <div style="margin:0px 10px 20px 0px">
        We provide expert-level support for your B2B channel, including managing discount pricing, 
        requests for quote, and how to position yourself as a B2B seller.
      </div>
      <div class="centerText">
        <a mat-raised-button routerLink="/services/b2b">Learn More</a>
      </div>
    </div>
  </div>
</section>

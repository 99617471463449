<section class="centerDiv" style="padding-bottom:20px">
  <div class="standardSideMargins">
    <h2 class="mat-headline centerText" style="margin-top:20px; margin-bottom:5px">Sophisticated Analytics</h2>
    <h3 class="mat-title centerText" style="margin-bottom:0px">with just a few clicks</h3>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div>
        <p>
          Inventory management isn't straightforward. If you could just take recent sales rates, do some simple math, and have your replenishment number, you wouldn't be here. Neither would we. 
          There are other factors to take into account: whether or not you had inventory available for sale, seasonal sales spikes, outlier events like Prime Day, ads/promotions - you name it.
        </p>
        <p class="centerText">
          <i>That's</i> why we're here.
        </p>
      </div>
      <div>
        <h3 class="mat-title centerText">So how do we make your life easier?</h3>
        <div fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px">
          <mat-card>
            <div class="centerText">Reduce Lost Sales</div>
            <mat-card-content>
              Our recommendations have been proven to keep sufficient inventory while avoiding Long Term Storage Fees
            </mat-card-content>
          </mat-card>
          <mat-card>
            <div class="centerText">Save Time</div>
            <mat-card-content>
              Let's face it, gathering reports/data/etc. is almost as time consuming as processing it. Let us do the hard work.
            </mat-card-content>
          </mat-card>
          <mat-card>
            <div class="centerText">Save Money</div>
            <mat-card-content>
              Lots of ways we help here: more sales, better Amazon rating, more efficient ordering/shipping from your manufacturer/supplier.
            </mat-card-content>
          </mat-card>
          <mat-card>
            <div class="centerText">Improve FBA Rankings</div>
            <mat-card-content>
              Climbing up Amazon's rankings can be hard, but one thing we do know: keeping sellable inventory in stock is key.
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <h3 class="mat-title centerText" style="margin-top:20px">Seasonality/Trend Analysis</h3>
      <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="10px">
        <div>
          <p>A common complaints we hear from e-commerce sellers is that they struggle with planning for seasonal items, or things such as Prime Day.</p>
          <p>bloomRail's Inventory Forecaster's advanced modeling identifies items that are set to trend up (or down) over the next few months to help you with your inventory planning.</p>
        </div>
        <div>
          <mat-card><img style="height:300px" src="../../../../../assets/images/survey/inventory-challenges/yoyColumn_20220517.png"/></mat-card>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="lostSales" class="centerDiv backgroundcolor-sky" style="padding-top:5px; padding-bottom:20px">
  <div class="standardSideMargins">
    <h2 class="mat-headline centerText" style="margin-top:20px">Lost Sales and Stockouts</h2>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      <div>
        <p>Especially with today's volatile supply chains, it can be difficult to keep enough inventory to support sales. When this happens, there are a few negative consequences, including:</p>
        <ol>
          <li>Lost sales: can't sell inventory if there isn't any</li>
          <li>Complicates future inventory planning: cannot simply use sales rate over time to calculate sales rate</li>
          <li>Stockouts can negatively affect factors used by Amazon's rating algorithm, such as IPI</li>
        </ol>
        <p>By keeping track of historical inventory levels, bloomRail's Inventory Forecaster can mitigate these issues.</p>
      </div>
      <div class="centerText">
        <mat-card><img style="height:250px; max-width:100%" src="../../../../../assets/images/survey/inventory-challenges/sAndITable_20220517.png"/></mat-card>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="10px">
        <div class="centerText">
          <mat-card><img style="height:200px" src="../../../../../assets/images/survey/inventory-challenges/invColumns_20220517.png"/></mat-card>
        </div>
        <div>
          <p>With regards to sales history, Inventory Forecaster tracks sales only on days that you had inventory to support sales; for example if you sold 100 items over 30 days (roughtly 3.3 sales/day) but only had sellable inventory for 15 of those days, your sales rate is actually 6.6/day.</p>
          <p>Inventory Forecaster tracks all of this data behind the scenes, and for any time period you choose, can show you the percentage of that time you had inventory, roughly how many sales you lost, and the average sales rate for days that you had inventory to support it.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="automation" class="centerDiv" style="padding-top:5px; padding-bottom:20px">
  <div class="standardSideMargins">
    <h2 class="mat-headline centerText" style="margin-top:20px">Data Retrieval and Automation</h2>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div>
        <p>
          We've spoken with a <i>lot</i> of people who have various inventory management issues, but there's one near-constant complaint: time and energy is wasted on identifying and gathering the 
          right reports (from either Amazon or their own systems), consolidating/merging that data, and working through an overly complicated manual process to bend that data into a shape that is useful. 
          This is tedious whether you do your restock calculations on a daily, weekly, or even monthly basis.
        </p>
      </div>
    </div>
  </div>
</section>
<section id="overstockAndStorage" class="centerDiv backgroundcolor-sage" style="padding-top:5px; padding-bottom:20px">
  <div class="standardSideMargins">
    <h2 class="mat-headline centerText" style="margin-top:20px">Overstock and Storage Issues</h2>
    <div fxLayout="column" fxLayoutAlign="center center">
        <p>
          Keeping <i>enough</i> inventory on hand when selling through FBA is a challenge, but it's all too easy to lose track of when there is <i>too much</i>. Amazon's <a href="https://sellercentral.amazon.com/gp/help/external/GJQNPA23YWVA4SBD">Long Term Storage Fees (LTSF)</a> 
          kick in when a product has been sitting on a shelf for more than 365 days. To avoid these fees, you can sell through the product (promotions, ads, or 
          <a href="https://sellercentral.amazon.com/gp/help/external/GHLYT4TPVCY2MJE3?language=en_US&ref=efph_GHLYT4TPVCY2MJE3_cont_GJQNPA23YWVA4SBD">Amazon Outlet</a> can help here), or set up a 
          <a href="https://sellercentral.amazon.com/gp/help/external/G201436560?language=en_US&ref=efph_G201436560_cont_GJQNPA23YWVA4SBD">removal or disposal order</a>.
        </p>
        <mat-card><img style="height:300px" src="../../../../../assets/images/survey/inventory-challenges/ltsf_20220517.png"/></mat-card>
        <p>
          bloomRail's Overstock Manager not only identifies which inventory is either in LTSF status or will be soon, but also advises on how to best handle it based on the sales period of your choosing. For example, for some products, it may be worth it to
          continue selling through the product, vs. others where it might be cheaper to dispose of the items. By comparing pre-calculated LTSF removal fees vs. the LTSF that would accrue while selling through the product, you can make an educated decision on 
          the best path forward
        </p>
    </div>
  </div>
</section>
import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-post20220209',
  templateUrl: './post20220209.component.html',
  styleUrls: ['./post20220209.component.scss']
})
export class Post20220209Component implements OnInit {

  baseUrlPortal: string;

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.baseUrlPortal = environment.BASE_URL_PORTAL;
    
    this.seoService.updateSocialTags(
      'brBlog: Domestic Manufacturing and e-Commerce', 
      'Domestic Manufacturing Insulates Your E-commerce Business from Compounded Global Supply Chain Issues...', 
      '/assets/images/icons/diagram.png', 
      '/blog/posts/post-20220209-domestic-manufacturing-for-resilient-supply-chain-management');
  }

}

<section class="blogMargins" [ngStyle.lt-md]="{'width':'70%'}">
  <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom:20px">
    <div>
      <img src="../../../../../../assets/images/logoOnly_green.png" style="width:20px"/>
    </div>
    <div class="mat-title" style="margin-bottom:0px; margin-left:5px">brBlog</div>
  </div>
  <h1 class="mat-display-1" style="margin-bottom:5px">Be Helpful and Do Good Work</h1>
  <div style="font-size:16px; font-style:italic; margin-bottom:20px">Why We Left Lucrative, Stable Jobs to Launch bloomRail</div>
  <div style="margin-bottom:20px"><span style="font-weight:450">by <a href="https://www.linkedin.com/in/allenedgarrogers" target="_blank">ALLEN E. ROGERS</a></span> - October 10, 2020</div>
  <div class="blogBody">
    <p>
      In 2014, I landed an opportunity that changed my career - and life - trajectory. Amazon was opening its B2B marketplace and a good friend happened to be on the team. He knew I was hunting for challenging work with solid pay and stability and he referred me for an entry-level role helping sellers launch on the new marketplace. I jumped through the interview hoops and received an offer.
    </p>
    <p>
      With no corporate experience and a mound of debt from my first entrepreneurial endeavor - a healthy food grocery store in my neighborhood in East Austin, Texas - I leaped. I accepted the offer and moved to the PNW with my fiancée and basset hound.
    </p>
    <p>
      My time at Amazon was kind to me. I learned much from the bright and driven people I worked with and developed many new skills. With a signing bonus, valuable stock, and enough pay to live comfortably, I was able to incrementally, but actively, pay off debt. My fiancée and I were married and had a baby.
    </p>
    <p>
      In 2017, we moved across the country again, this time to Virginia where my wife's family was based. I was able to work remotely with my Seattle team at a time where virtual work was still a bit outside the box. I made steady progress at Amazon, earning promotions and becoming a team veteran and valuable resource. I acted as project lead for complex and high-stakes integration projects, traveled to domestic and international client sites, and had the good fortune of reporting to two of the kindest managers Amazon had to offer.
    </p>
    <p>
      For all the flexibility, stability, and growth potential I earned at Amazon, my heart still yearned for the free-form problem solving entrepreneurship promises. One of my greatest challenges at Amazon was feeling overburdened with internal demands and being left without the bandwidth to address pressing client issues. I also had to navigate the sometimes murky space between doing what was in my employer's best interest and being a genuine advocate for the seller's needs. This gnawed at me.
    </p>
    <p>
      Over family dinner one night, I turned to my brother-in-law, Will - a longtime software developer and leader for a federal government contractor. Various connections had reached out to me looking for help with launching, scaling, and automating their online businesses. Was there an opportunity here?
    </p>
    <p>
      I knew Will to be fastidious in his work, dynamic in his thinking, and a reliable friend. I also knew he was a crack developer. We considered the potential complications that could arise in starting a family business. Would we be a good fit? Did he believe I had what it took to make a company work? 
    </p>
    <p>
      Over several months, we continued to explore what it would look like to partner and offer our services, perhaps as a side project while we held down our existing (read: reliable) jobs.
    </p>
    <p>
      But soon we found that what intrigued us most was precisely what my experience at Amazon had proven - that all sellers, from mom & pop shops to Fortune 100 companies, needed help navigating the myriad complexities of the e-commerce world. 
    </p>
    <div class="mat-headline" style="margin-bottom:5px">The Decision</div>
    <p>
      As we talked, researched, and consulted with an attorney and Amazon HR, we determined that a side effort would tread too closely to my official job duties. We had to make a decision - stay put or go all in.
    </p>
    <p>
      We leaped. With the support of our families, Will and I resigned from our jobs in the summer of 2019 with a handful of clients ready to bring us on. Did I mention that my wife and I had just learned we were expecting our second baby?
    </p>
    <div class="mat-headline" style="margin-bottom:5px">Why bloomRail?</div>
    <p>
      We initially founded the business as a simple consultancy. Our focus wasn't building up a company and brand for a public audience. We hoped to build a client base through existing professional networks and word-of-mouth, picking up projects that suited our competencies to build credibility.
    </p>
    <p>
      But after a few months of ad hoc project work, I began analyzing the common threads I saw throughout my experience at Amazon and in working directly for clients.
    </p>
    <p>
      Every business has its own unique problem-set, but the overall framework for e-commerce success remains similar. Sellers must adhere to the fundamentals of building up a viable business that proactively manages risk and builds credibility in the marketplace, even as competition continues to increase. We asked ourselves: how do we bring this solution framework to sellers at scale?
    </p>
    <div>
      We decided to launch bloomRail with three goals:
      <ol style="margin-top:0px">
        <li>Help every seller lay the tracks for success. </li>
        <li>Equip each with the correct tools to execute and grow.</li>
        <li>Guide them down the path.</li>
      </ol>
    </div>
    <p>
      In practicality, achieving these goals means delivering the results of hands-on project management paired with implementing robust software tools.
    </p>
    <div>
      So, for every seller we work with, we promise two things:
      <ol style="margin-top:0px">
        <li>If we haven’t built the tool you need yet, we will, or we will turn to our trusted partner network to find the right solution.</li>
        <li>We will see to it your operations are integrated, automated, and optimized to your level of satisfaction.</li>
      </ol>
    </div>
    <p>
      We want every interaction to leave our clients feeling they received a premium value and are empowered to push their businesses to the next level.
    </p>
    <p>
      Will and I have spent our careers distilling complex concepts into approachable, actionable ideas. With bloomRail, we want to be helpful and do good work for as many folks as we can. We're jumping in.
    </p>
    <div class="mat-headline" style="margin-bottom:5px">Get in Touch</div>
    <p>
      We love to hear from anyone who needs help or advice. Reach out to us through the <a routerLink="/contact" target="_blank">bloomRail</a> Contact page, or you can connect with me directly on <a href="https://twitter.com/aerogers" target="_blank">Twitter</a> and <a href="https://www.linkedin.com/in/allenedgarrogers/" target="_blank">LinkedIn</a>. 
    </p>
    <div>
      If you liked this story, check out these articles about my early career transitions between business and teaching:
      <ul>
        <li><a href="https://www.resume-now.com/job-resources/careers/teaching/career-change-into-teaching" target="_blank">How I Made a Career Change Into Teaching</a></li>
        <li><a href="https://www.resume-now.com/job-resources/careers/teaching/become-a-corporate-trainer" target="_blank">How to Become a Corporate Trainer</a></li>
      </ul>
    </div>
  </div>
</section>
<section class="blogMargins" [ngStyle.lt-md]="{'width':'70%'}">
  <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom:20px">
    <div>
      <img src="../../../../../../assets/images/logoOnly_green.png" style="width:20px"/>
    </div>
    <div class="mat-title" style="margin-bottom:0px; margin-left:5px">brBlog</div>
  </div>
  <h1 class="mat-display-1" style="margin-bottom:5px">What Is Gumroad?</h1>
  <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - July 8, 2021</div>
  <div class="blogBody">
    <p>
      Gumroad is a tool that makes it quick and simple for creators and entrepreneurs to sell online. It’s an e-commerce solution for artists, curriculum-developers, designers, app & software developers, educators, writers, authors, and anyone else who has something to sell and needs a way to sell it. Creatives and subject-matter-experts use it to sell <a href="https://kevincarden.gumroad.com/l/Irsuv" target="_blank">video streams</a>, educational courses and guides, digital downloads of any type (such as <a href="https://blossomandroot.gumroad.com/l/pYoMW" target="_blank">PDFs</a>), physical products to be shipped, rentals, <a href="https://dvassallo.gumroad.com/l/aws-good-parts/" target="_blank">books</a> (both digital and physical), pre-orders, membership products with recurring subscription fees, full-on apps and software with Gumroad-provided license-keys, you name it. 
    </p>
    <p>
      There are numerous stories of creators taking their knowledge and skills and using Gumroad as a pathway to sustainable entrepreneurship--from <a href="https://gumroad.gumroad.com/p/creator-spotlight-kristina-garner-s-success-on-gumroad" target="_blank">a teacher</a> developing and selling her homeschool curriculum online, to <a href="https://gumroad.gumroad.com/p/creator-spotlight-daniel-vassallo-s-success-on-gumroad" target="_blank">a former Amazon employee</a> using his expertise to sell instructional books and video streams. Gumroad also supports visual <a href="https://gumroad.gumroad.com/p/creator-spotlight-floortje-visser-s-success-on-gumroad" target="_blank">artists</a>, musicians, comic book creators, and many others.
    </p>
    <p class="centerText mat-headline">
      Why Should I use Gumroad?
    </p>
    <div>
      <div class="mat-headline" style="margin-bottom:5px">1. Quick & Easy</div>
    </div>
    <p>
      For many creators, thinking about getting started with marketing and pushing sales in e-commerce can be daunting; creating excellent content and products is the area where they shine, not sales. Gumroad is deliberately simple to make it fast and easy to create sales pages for your products and content. You’ll have an online profile page and individual product links--and that’s it.
    </p>
    <p>
      The interface is very basic and easy to set up, so you won’t need tutorials or much instruction to get started. And it’s open-ended, allowing you to sell any kind of digital or physical product you want (note: Gumroad only works for selling products, not services).
    </p>
    <div>
      <div class="mat-headline" style="margin-bottom:5px">2. Affordable</div>
    </div>
    <p>
      In addition to being one of the easiest ways to sell products online, it’s also one of the most affordable. 
    </p>
    <div>
      Gumroad offers <a href="https://gumroad.com/features/pricing" target="_blank">two pricing plans</a>:
      <ul>
        <li>Free (unlimited products and sales at an 8.5% commission and $0.30 per sale, with fewer features)</li>
        <li>$10/month (unlimited products and sales at 3.5% commission and $0.30 per sale, with more premium features)</li>
      </ul>
    </div>
    <div>
      <div class="mat-headline" style="margin-bottom:5px">3. Marketing Power</div>
    </div>
    <p>
      Despite (and, in some cases, because of) its intentional simplicity, Gumroad has <a href="https://welovenocode.com/blog/tpost/7jtvaxil81-what-is-gumroad-ultimate-review-amp-tuto" target="_blank">a load of features</a> and marketing power under the hood. Like a social media site, you can <a href="https://help.gumroad.com/article/170-audience" target="_blank">develop a group of followers</a> on Gumroad and send posts and marketing-email updates to the group. Checkouts are a breeze for users and allow for credit cards or PayPal. And the best part--you will get your deposit every Friday.
    </p>
    <p>
      Customers can create an account where they can store their digital purchases, which can come in handy for creators selling digital art and content. Gumroad’s streaming works great for video and music files, allowing your customers to view their purchases quickly without downloading. For downloads, you can send files to a customer’s dropbox, Kindle, etc. You can easily set Gumroad up for selling physical products that you ship, as well. 
    </p>
    <p>
      For such a simple tool, Gumroad provides some decent analytics and reporting to help you view trends and grow your business--and working with a company like bloomRail can take it to the next level. Gumroad also allows you to connect with customers by sending automatic thank yous, as well as promo offers and discount codes, while amassing followers and sending out updates and marketing emails. 
    </p>
    <p>
      An important aspect of using Gumroad is integrating it with your other social media channels and websites. You can use the provided ‘Buy My Product’ widget on your website or blog and take advantage of Gumroad’s smooth third-party integration with <a href="https://wordpress.com/" target="_blank">WordPress</a>, <a href="https://www.weebly.com/" target="_blank">Weebly</a>, <a href="https://www.squarespace.com/" target="_blank">Squarespace</a>, <a href="https://www.tumblr.com/" target="_blank">Tumblr</a>, <a href="https://www.drip.com/" target="_blank">Drip</a>, and <a href="https://convertkit.com/" target="_blank">Covertkit</a>. 
    </p>
    <div class="centerText mat-headline">
      How Do I Get Started with Gumroad?
    </div>
    <p>
      Building a profile and posting your products for sale on Gumroad only takes a few minutes. You’ll simply:
    </p>
    <div>
      <ul>
        <li>Create an account for free (You can later pay for premium features if you choose to do so)</li>
        <li>Add payment details so you get your deposit every Friday</li>
        <li>Upload a photo and short bio to your profile page and begin uploading your products and content</li>
        <li>Use the links provided to share your profile and products, and start selling</li>
      </ul>
    </div>
    <p>
      If you’d like to learn more about whether a tool like Gumroad is the right one for selling your products and content, the seasoned e-commerce experts at bloomRail are here to help. We love talking about this stuff, so hit the Contact Us button below and take advantage of our free consultation, anytime!
    </p>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div><a mat-raised-button routerLink="/contact">Contact bloomRail</a></div>
      <div class="mat-caption" style="margin-top:10px">
        Learn More
      </div>
    </div>
  </div>
</section>
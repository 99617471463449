<div style="height:100%">
    <div fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
      <div>
        <mat-icon style="font-size:100px; margin-right:10px" inline="true">error_outline</mat-icon>
      </div>
      <div>
        <div class="mat-display-1" style="margin-bottom:10px">
          Nothing to see here!
        </div>
        <div style="font-family: Poppins">
          Either the link you used is incorrect, out of date, or had a typo.<br/>
          If you feel this is in error, please reach out to <a href="mailto:contact@bloomrail.com">contact@bloomrail.com.</a>
        </div>
      </div>
    </div>
</div>
<div class="centerText mat-display-1 backgroundcolor-sky color-primary" style="padding-top:40px; padding-bottom:40px; margin-bottom:0px">bloomRail in the News</div>
<div fxLayout="column" fxLayoutAlign="center center">
  <table mat-table [dataSource]="pressReleases" class="mat-elevation-z1" style="margin-top:20px; margin-bottom:20px; width:auto">
    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">{{ element.releaseDate | date:'MM/dd/yyyy'}}</td>
    </ng-container>
    <ng-container matColumnDef="Title">
      <th mat-header-cell *matHeaderCellDef style="padding-left:20px">Title</th>
      <td mat-cell *matCellDef="let element" style="padding-left:20px">{{ element.title }}</td>
    </ng-container>
    <ng-container matColumnDef="Link">
      <th mat-header-cell *matHeaderCellDef style="padding-left:20px">Link</th>
      <td mat-cell *matCellDef="let element" style="padding-left:20px">
        <button *ngIf="!element.external" mat-raised-button color="primary" routerLink="{{ element.link }}">View</button>
        <a mat-button *ngIf="element.external" mat-raised-button color="primary" href="{{ element.link }}" target="_blank">View</a>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
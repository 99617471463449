import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { User, Preference, BrSubscription } from '../model/account/user';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  authenticated: boolean = false;
  user: User = new User();

  apiUrl:string = '/api';

  constructor(private http: HttpClient, private snackBar: MatSnackBar, private dialog: MatDialog) {

  }

  initCsrf() {
    return this.http.get(this.apiUrl + '/account/initCsrf').pipe();
  }

  login(username: string, password: string) {
    this.user.username = username;
    this.user.password = password;

    return this.http.post<any>(this.apiUrl + '/account/login', this.user)
      .pipe<User>(map(data => {
        this.user.firstName = data.firstName;
        this.user.lastName = data.lastName;
        this.user.merchantId = data.mwsAccount.merchantId;
        this.user.company = data.mwsAccount.company;
        this.user.stripeCustId = data.mwsAccount.stripeCustId;
        if(data.mwsAccount.subscriptions != null) {
          for(var val of data.mwsAccount.subscriptions) {
            val.currentPeriodEnd = new Date(val.currentPeriodEnd);
          }
          this.user.subscriptions = data.mwsAccount.subscriptions;
        } else {
          this.user.subscriptions = new Array();
        }
        
        this.user.preferences = data.preferences;
        this.authenticated = true;
        return this.user;
      }));
  }

  /**
   * Returns the status of the user's FBA Inventory Forecaster subscription. Acceptable
   * return values are trial, active, trialing-expired, active-expired, and never.
   * 
   * @returns 
   */
  private getSubscriptionStatus(subscriptionType: string): string {
    let status: string = 'never';

    if(this.user.subscriptions != null) {
      for(var val of this.user.subscriptions) {
        if(val.subscriptionType == subscriptionType) {
          if(val.currentPeriodEnd != null) {
            let today: Date = new Date();
            let gracePeriodEnd: Date = new Date();
            gracePeriodEnd.setDate(val.currentPeriodEnd.getDate() + 7);
            if(today < val.currentPeriodEnd) {
              return val.subscriptionStatus;
            } else if(today > val.currentPeriodEnd && today < gracePeriodEnd) {
              // In grace period
              status = val.subscriptionStatus;
              let daysLeft: number = gracePeriodEnd.getDate() - today.getDate();
              this.displaySnackbarInfo('Your account has expired. Please update your billing information in User Preferences in the next ' +  daysLeft + ' days to keep your subscription active.');
            } else {
              if('active' == val.subscriptionStatus) {
                status = 'active-expired';
              } else if('trialing' == val.subscriptionStatus) {
                status = 'trialing-expired';
              }
            }
          }
        }
      }
    }

    return status;
  }

  private setSubscriptionStatus(subscriptionType: string, status: string) {
    let subscription: BrSubscription = this.findOrCreateSubscription(subscriptionType);
    subscription.subscriptionStatus = status;
  }

  private getSubscriptionStatusPeriodEnd(subscriptionType: string): Date {
    let periodEnd: Date;

    for(var val of this.user.subscriptions) {
      if(val.subscriptionType == subscriptionType) {
        periodEnd = val.currentPeriodEnd;
      }
    }

    return periodEnd;
  }

  private setSubscriptionStatusPeriodEnd(subscriptionType: string, periodEnd: Date) {
    let subscription: BrSubscription = this.findOrCreateSubscription(subscriptionType);
    subscription.currentPeriodEnd = periodEnd;
  }

  private findOrCreateSubscription(subscriptionType: string): BrSubscription {
    let toReturn: BrSubscription;

    for(var val of this.user.subscriptions) {
      if(val.subscriptionType == subscriptionType) {
        toReturn = val;
      }
    }

    if(toReturn == null) {
      toReturn = new BrSubscription();
      toReturn.subscriptionType = subscriptionType;
      this.user.subscriptions.push(toReturn);
    }

    return toReturn;
  }

  getFbaInventoryMgrSubscriptionStatus(): string {
    return this.getSubscriptionStatus('fbaInventoryMgr');
  }

  setFbaInventoryMgrSubscriptionStatus(status: string) {
    this.setSubscriptionStatus('fbaInventoryMgr', status);
  }

  getFbaInventoryMgrSubscriptionPeriodEnd(): Date {
    return this.getSubscriptionStatusPeriodEnd('fbaInventoryMgr');
  }

  setFbaInventoryMgrSubscriptionPeriodEnd(periodEnd: Date) {
    if(periodEnd != null) {
      periodEnd = new Date(periodEnd);
      this.setSubscriptionStatusPeriodEnd('fbaInventoryMgr', periodEnd);
    }
  }

  areMwsReportsUpToDate(): Observable<boolean> {
    let apiUrl = this.apiUrl + '/userPayment/areMwsReportsUpToDate?merchantId=' + this.user.merchantId;
    return this.http.get<any>(apiUrl).pipe();
  }

  /**
   * Invalidates front-end credentials and also calls logout to clear server-side
   * session. Clearing front-end first to cover the odd case of the back-end call
   * failing.
   */
  logout() {
    this.dialog.closeAll();
    let tempUser:User = this.user;
    this.clearCredentials();
    this.http.post<any>(this.apiUrl + '/account/logout', tempUser).pipe().subscribe();
  }

  clearCredentials() {
    this.authenticated = false;
    this.user = new User();
  }

  /**
   * For a given preference name, updates the existing value or creates
   * a new preference and sets the value there.
   * 
   * @param preferenceName 
   * @param preferenceValue 
   */
  private updatePreferenceInSession(preferenceName:string, preferenceValue:string) {
    let valueFound:boolean = false;
    for(var val of this.user.preferences) {
      if(val.preferenceName == preferenceName) {
        valueFound = true;
        val.preferenceValue = preferenceValue;
      }
    }
    if(!valueFound) {
      this.user.preferences.push({id:0, preferenceName: preferenceName, preferenceValue: preferenceValue});
    }
  }

  createAccount(user:User): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/account/createAccount', user).pipe();
  }

  verifyAccount(username:string, verificationCode:string): Observable<any> {
    let data = {username:username, verificationCode:verificationCode};
    return this.http.post<any>(this.apiUrl + '/account/verifyAccount', data).pipe();
  }

  sendVerificationCode(username: string): Observable<any> {
    let data = {username:username}
    return this.http.post<any>(this.apiUrl + '/account/sendVerificationCode', data).pipe();
  }

  resetPassword(username: string, verificationCode: string, newPassword: string): Observable<any> {
    let data = {username:username, verificationCode:verificationCode, password:newPassword};
    return this.http.post<any>(this.apiUrl + '/account/resetPassword', data).pipe();
  }

  savePreferenceByNVPair(preferenceName: string, preferenceValue: string) {
    let pref: Preference = new Preference();
    pref.preferenceName = preferenceName;
    pref.preferenceValue = preferenceValue;
    return this.savePreference(pref);
  }

  savePreference(preference: Preference) {
    let prefArray: Preference[] = new Array();
    prefArray.push(preference);
    return this.savePreferences(prefArray);
  }

  savePreferences(preferences: Preference[]) {
    for(var pref of preferences) {
      this.updatePreferenceInSession(pref.preferenceName, pref.preferenceValue);
    }
    return this.http.post<any>(this.apiUrl + '/preferences/setPreferences?merchantId=' + this.user.merchantId + '&username=' + this.user.username, preferences).pipe();
  }

  getMailingListSubscriptionsAsPreferences(merchantId: string, username: string): Observable<Preference[]> {
    let apiUrl = this.apiUrl + '/preferences/getMailingListSubscriptionsAsPreferences?merchantId=' + merchantId + '&username=' + username;
    return this.http.get<any>(apiUrl).pipe(map(data=>data));
  }

  reportError(code: string, error: Error){
    let stack: string = code + ': ' + error.message;
    this.http.post<any>(this.apiUrl + '/metadata/error/logFrontendError', stack).pipe().subscribe();
  }

  displaySnackbarInfo(message: string) {
    this.snackBar.open(message, null, {
      duration: 10000
    });
  }

  displaySnackbarError(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000, panelClass: ['snackbarError']
    });
  }
}

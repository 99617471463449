<div class="blogListMargins" style="margin-top:40px">
  <section>
    <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom:20px">
      <div>
        <img src="../../../../../../assets/images/logoOnly_green.png" style="width:50px"/>
      </div>
      <div style="margin-left:10px">
        <h1 class="mat-display-1" style="margin-bottom:0px">bloomRail Blog</h1>
        <div>Industry insights from our staff</div>
      </div>
    </div>
  </section>
  <section>
    <div>
      <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-evently center">
          <div [ngStyle.lt-md]="{'display':'none'}">
            <img src="../../../../../assets/images/icons/diagram.png" style="width:150px; padding-left:7px; padding-right:7px"/>
          </div>
          <div style="margin-left:20px">
            <div class="mat-title" style="margin-bottom:0px"><a routerLink="/blog/posts/post-20220209-domestic-manufacturing-for-resilient-supply-chain-management">Domestic Manufacturing for Resilient Supply Chain Management</a></div>
            <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - February 9, 2022</div>
            <div class="blogSnippit">We've all seen the news lately about global supply chain issues due to the lingering effects of covid-19 and the surge in consumer demand as we recover from it....</div>
          </div>
        </div>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-evently center">
          <div [ngStyle.lt-md]="{'display':'none'}">
            <img src="../../../../../assets/images/icons/planet_cityskyline.png" style="width:150px; padding-left:7px; padding-right:7px"/>
          </div>
          <div style="margin-left:20px">
            <div class="mat-title" style="margin-bottom:0px"><a routerLink="/blog/posts/post-20220202-environmental-sustainability-is-good-for-the-e-commerce-bottom-line">Environmental Sustainability Is Good for the E-Commerce Bottom Line</a></div>
            <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - February 2, 2022</div>
            <div class="blogSnippit">For an ecommerce entrepreneur running a ​​small, medium, or large online business, there are many types of marketing strategies to consider. Some involve expenses that can eat up your marketing budget quickly, but there are plenty of essential strategies...</div>
          </div>
        </div>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-evently center">
          <div [ngStyle.lt-md]="{'display':'none'}">
            <img src="../../../../../assets/images/blog/2021/digital-marketing-with-a-megaphone-concept-flat-design.jpg" style="width:150px; padding-left:7px; padding-right:7px"/>
          </div>
          <div style="margin-left:20px">
            <div class="mat-title" style="margin-bottom:0px"><a routerLink="/blog/posts/post-20211123-4-low-cost-ecommerce-marketing-strategies">4 Low-Cost Ecommerce Marketing Strategies</a></div>
            <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - November 23, 2021</div>
            <div class="blogSnippit">For an ecommerce entrepreneur running a ​​small, medium, or large online business, there are many types of marketing strategies to consider. Some involve expenses that can eat up your marketing budget quickly, but there are plenty of essential strategies...</div>
          </div>
        </div>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-evently center">
          <div [ngStyle.lt-md]="{'display':'none'}">
            <img src="../../../../../assets/images/blog/2021/engine_ss.png" style="width:150px; padding-left:7px; padding-right:7px"/>
          </div>
          <div style="margin-left:20px">
            <div class="mat-title" style="margin-bottom:0px"><a routerLink="/blog/posts/post-20211025-retargeting-to-boost-roi-for-online-advertising">Retargeting To Boost ROI for Online Advertising</a></div>
            <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - October 25, 2021</div>
            <div class="blogSnippit">By some estimated averages, only 4% of visitors to an e-commerce store are ready to buy: 96% of your visitors are just browsing and could come back later--but only if they remember it! The natural and effective response to this frightening statistic is to focus much of your online advertising on previously-engaged consumers, via retargeting strategies. Let’s get into how it works...</div>
          </div>
        </div>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-evently center">
          <div [ngStyle.lt-md]="{'display':'none'}">
            <img src="../../../../../assets/images/blog/2021/aarrrFunnel.jpg" style="width:150px; padding-left:7px; padding-right:7px"/>
          </div>
          <div style="margin-left:20px">
            <div class="mat-title" style="margin-bottom:0px"><a routerLink="/blog/posts/post-20211011-AARRR-pirate-metrics-for-ecommerce-analytics">AARRR - Pirate Metrics for Ecommerce Analytics</a></div>
            <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - October 11, 2021</div>
            <div class="blogSnippit">What is Ecommerce Analytics? Whether you’re just beginning to sell your products online via Amazon, running a business via Woocommerce or Shopify, or managing an enterprise-level organization, acting and planning without studying data and metrics is a lot like driving a car while wearing a blindfold...</div>
          </div>
        </div>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-evently center">
          <div [ngStyle.lt-md]="{'display':'none'}">
            <img src="../../../../../assets/images/companyLogos/gumroad-logo.png" style="width:150px; padding-left:7px; padding-right:7px"/>
          </div>
          <div style="margin-left:20px">
            <div class="mat-title" style="margin-bottom:0px"><a routerLink="/blog/posts/post-20210708-what-is-gumroad">What Is Gumroad?</a></div>
            <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - July 8, 2021</div>
            <div class="blogSnippit">Gumroad is a tool that makes it quick and simple for creators and entrepreneurs to sell online. It’s an e-commerce solution for artists, curriculum-developers, designers, app & software developers, educators, writers, authors, and anyone else who has something to sell and needs a way to sell it...</div>
          </div>
        </div>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-evently center">
          <div [ngStyle.lt-md]="{'display':'none'}">
            <img src="../../../../../assets/images/icons/container.png" style="width:150px; padding-left:7px; padding-right:7px"/>
          </div>
          <div style="margin-left:20px">
            <div class="mat-title" style="margin-bottom:0px"><a routerLink="/blog/posts/post-20210627-3-reasons-inventory-management-matters">3 Reasons Inventory Management Matters</a></div>
            <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - June 27, 2021</div>
            <div class="blogSnippit">For a small business, an enterprise-level corporation, or anything in between, maintaining an excellent inventory management system is essential for success in e-commerce. Keeping tight controls on your channels leads to robust and sustainable growth, happier customers...</div>
          </div>
        </div>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-evently center">
          <div [ngStyle.lt-md]="{'display':'none'}">
            <img src="../../../../../assets/images/icons/virus.jpg" style="width:150px; padding-left:7px; padding-right:7px"/>
          </div>
          <div style="margin-left:20px">
            <div class="mat-title" style="margin-bottom:0px"><a routerLink="/blog/posts/post-20210504-seeking-clarity-how-businesses-have-adjusted-for-covid-19">Seeking Clarity – How Businesses Have Adjusted for Covid-19</a></div>
            <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - May 4, 2021</div>
            <div class="blogSnippit">Little more than a year ago, a global pandemic turned our world upside down. In an instant, even the most standard activities were halted. Many people became afraid to see loved ones, go to school...</div>
          </div>
        </div>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-evently center">
          <div [ngStyle.lt-md]="{'display':'none'}">
            <img src="../../../../../assets/images/services/logoShopify.png" style="height:150px; padding-left:7px; padding-right:7px"/>
          </div>
          <div style="margin-left:20px">
            <div class="mat-title" style="margin-bottom:0px"><a routerLink="/blog/posts/post-20210330-5-musts-when-you-start-a-shopify-store">Top 5 Musts When You Start a Shopify Store</a></div>
            <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - March 30, 2021</div>
            <div class="blogSnippit">Shopify has become the leading e-commerce platform on the web, boasting over 800,000 online stores. Its key to success: the ease in which you can launch and manage a storefront...</div>
          </div>
        </div>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-evently center">
          <div [ngStyle.lt-md]="{'display':'none'}">
            <img src="../../../../../assets/images/icons/funnel.png" style="height:150px"/>
          </div>
          <div style="margin-left:20px">
            <div class="mat-title" style="margin-bottom:0px"><a routerLink="/blog/posts/post-20201130-5-rules-for-picking-high-quality-vendors-for-your-e-commerce-business">5 Rules for Picking High-Quality Vendors for Your E-commerce Business</a></div>
            <div style="margin-bottom:20px"><span style="font-weight:450">by <a href="https://www.linkedin.com/in/allenedgarrogers" target="_blank">ALLEN E. ROGERS</a></span> - November 30, 2020</div>
            <div class="blogSnippit">Whether launching your business, growing an existing channel, or expanding to a new one, you'll need vendors that support your e-commerce business’s complex needs...</div>
          </div>
        </div>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-evently center">
          <div [ngStyle.lt-md]="{'display':'none'}">
            <img src="../../../../../assets/images/icons/arrowSign.png" style="height:150px"/>
          </div>
          <div style="margin-left:20px">
            <div class="mat-title" style="margin-bottom:0px"><a routerLink="/blog/posts/post-20201012-planning-for-long-term-growth-in-e-commerce">Planning for long-term growth in e-commerce</a></div>
            <div style="margin-bottom:20px"><span style="font-weight:450">by <a href="https://www.linkedin.com/in/allenedgarrogers" target="_blank">ALLEN E. ROGERS</a></span> - October 12, 2020</div>
            <div class="blogSnippit">If you have a product to sell and an internet connection, you can launch an online store. Imagine your successful e-commerce business—generating income in a rapid-growth industry and making it possible to build a flexible, virtual working life...</div>
          </div>
        </div>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <div fxLayout="row" fxLayoutAlign="space-evently center">
          <div [ngStyle.lt-md]="{'display':'none'}">
            <img src="../../../../../assets/images/logoOnly_green.png" style="margin:6px 6px 6px 6px; height:138px"/>
          </div>
          <div style="margin-left:20px">
            <div class="mat-title" style="margin-bottom:0px"><a routerLink="/blog/posts/post-20201010-be-helpful-and-do-good-work">Be Helpful and Do Good Work</a></div>
            <div style="font-style:italic; margin-bottom:7px">Why We Left Lucrative, Stable Jobs to Launch bloomRail</div>
            <div style="margin-bottom:20px"><span style="font-weight:450">by <a href="https://www.linkedin.com/in/allenedgarrogers" target="_blank">ALLEN E. ROGERS</a></span> - October 10, 2020</div>
            <div class="blogSnippit">In 2014, I landed an opportunity that changed my career - and life - trajectory. Amazon was opening its B2B marketplace and a good friend happened to be on the team. He knew I was hunting for challenging work with solid pay and stability and he...</div>
          </div>
        </div>
      </mat-card>
    </div>
  </section>
</div>
import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

@Component({
  selector: 'app-press-release20201022',
  templateUrl: './press-release20201022.component.html',
  styleUrls: ['./press-release20201022.component.scss']
})
export class PressRelease20201022Component implements OnInit {

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.seoService.updateSocialTags(
      'bloomRail Launches!', 
      'bloomRail Launches Site and Debuts Restock Forecaster Tool', 
      '/assets/images/logoOnly_green.png', 
      '/press/articles/2020-10-22-press-release-bloomrail-launch');
    
    this.seoService.updateDescription('bloomRail Launches Site and Debuts Restock Forecaster Tool. Richmond-based SaaS Startup will Support and Empower E-commerce Sellers');
  }

}

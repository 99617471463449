<link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
<div fxLayout="column" fxFlexFill>
  <header id="header" [ngClass]="headerBgClass" style="width:100%; position:fixed; top:0; right:0; left:0; z-index:1000;">
    <div style="color:inherit">
      <mat-toolbar style="width:90%; background:none; color:inherit; margin:0 auto;">
        <mat-toolbar-row style="color:inherit; height:64px;">
          <button mat-icon-button fxShow="true" fxHide.gt-sm [matMenuTriggerFor]="mobileMenu">
            <mat-icon style="color:inherit">menu</mat-icon>
          </button>
          <div>
            <a routerLink="/" style="font-family:Poppins; color:inherit; text-decoration:none;">
              <div fxLayout="row" fxLayoutAlign="none center">
                <img src="../assets/images/logoOnly_green.png" style="width:25px;"/>
                <div><span style="margin-left:10px">bloomRail</span></div>
              </div>
            </a>
          </div>
          <span class="menu-spacer"></span>
          <div fxShow="true" fxHide.lt-md>
            <!-- <a mat-button [matMenuTriggerFor]="softwareMenu">Products<mat-icon>keyboard_arrow_down</mat-icon></a> -->
            <a mat-button routerLink="/product-info/inventory-forecaster">The Product</a>
            <a mat-button href="https://calendly.com/bloomrail/standard" target="_blank">Request a Demo</a>
            <!-- <a mat-button [matMenuTriggerFor]="servicesMenu">Services<mat-icon>keyboard_arrow_down</mat-icon></a> -->
            <a mat-button routerLink="/pricing">Pricing</a>
            <!-- <a mat-button [matMenuTriggerFor]="resourcesMenu">Resources<mat-icon>keyboard_arrow_down</mat-icon></a> -->
            <a mat-button routerLink="/blog/list">Blog</a>
            <a *ngIf="!appService.authenticated" href="{{ baseUrlPortal }}/create-account" mat-button>Sign Up</a>
            <a style="margin-top:-10px; margin-left:10px;" mat-mini-fab [matMenuTriggerFor]="accountMenu"><mat-icon>account_circle</mat-icon></a>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>

      <mat-menu #softwareMenu>
        <button mat-menu-item routerLink="/product-info/inventory-forecaster">
          <span>Inventory Forecaster</span>
        </button>
      </mat-menu>
      
      <mat-menu #servicesMenu>
        <button mat-menu-item routerLink="/services/smb">
          <span>SMB</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/services/enterprise">
          <span>Enterprise</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/services/b2b">
          <span>B2B</span>
        </button>
      </mat-menu>

      <mat-menu #resourcesMenu>
        <button mat-menu-item routerLink="/partners">
          <span>Partners</span>
        </button>
        <button mat-menu-item routerLink="/blog/list">
          <span>brBlog</span>
        </button>
      </mat-menu>

      <mat-menu #accountMenu>
        <div *ngIf="!appService.authenticated">
          <a mat-menu-item href="{{ baseUrlPortal }}/login">
            <mat-icon>power</mat-icon>
            <span>Log In</span>
          </a>
          <mat-divider></mat-divider>
          <a mat-menu-item href="{{ baseUrlPortal }}/verify-account">
            <mat-icon>verified_user</mat-icon>
            <span>Verify Account with code</span>
          </a>
        </div>
      </mat-menu>

      <mat-menu #mobileMenu>
        <!-- <button mat-menu-item [matMenuTriggerFor]="softwareMenu"><span>Products</span></button> -->
        <button mat-menu-item routerLink="/product-info/inventory-forecaster"><span>The Product</span></button>
        <!-- <button mat-menu-item [matMenuTriggerFor]="servicesMenu"><span>Services</span></button> -->
        <button mat-menu-item routerLink="/pricing"><span>Pricing</span></button>
        <button mat-menu-item [matMenuTriggerFor]="resourcesMenu"><span>Resources</span></button>
        <a *ngIf="!appService.authenticated" mat-menu-item href="{{ baseUrlPortal }}/create-account"><span>Sign Up</span></a>
        <button mat-menu-item [matMenuTriggerFor]="accountMenu">Your Account</button>
      </mat-menu>
    </div>
  </header>

  <div [ngClass]="padContent" fxFlex>
    <router-outlet></router-outlet>
  </div>

  <footer id="footer">
    <div class="backgroundcolor-stonewash" style="padding-top:20px; padding-bottom:20px">
      <div class="container brandFont" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" [ngStyle.lt-md]="{'font-size':'12px'}">
        <div fxLayout="row">
          <div fxLayout="column" class="footerColumn">
            <div>Company</div>
            <span class="link"><a routerLink="/about">About</a></span>
            <span class="link"><a routerLink="/our-team">Team</a></span>
            <span class="link"><a routerLink="/contact">Contact</a></span>
            <span class="link"><a routerLink="/press/newsroom">Press</a></span>
          </div>
          <div fxLayout="column" class="footerColumn">
            <div>Resources</div>
            <!-- <a routerLink="resourceLaunchPlan">Launch Plan</a> -->
            <!-- <a routerLink="/how-to-register">How to Register</a> -->
            <span class="link"><a routerLink="/partners">Partners</a></span>
            <span class="link"><a routerLink="/blog/list">brBlog</a></span>
          </div>
          <!-- <div fxLayout="column" class="footerColumn">
            <div>Services</div>
            <a routerLink="/services/smb">SMB</a>
            <a routerLink="/services/enterprise">Enterprise</a>
            <a routerLink="/services/b2b">B2B</a>
          </div> -->
          <div fxLayout="column">
            <div>Products</div>
            <span class="link"><a routerLink="product-info/inventory-forecaster">Inventory Forecaster</a></span>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="centerText" style="font-weight:200">
            Learn more and stay in the loop with<br/> industry insights and helpful resources.
          </div>
          <div>
            <form (ngSubmit)="emailSubscription()">
              <div fxLayout="row" fxLayoutAlign="center center" style="margin-top:10px">
                <div>
                  <mat-form-field appearance="fill">
                    <mat-label>email address</mat-label>
                    <input matInput [formControl]="emailAddress">
                    <mat-error *ngIf="emailAddress.invalid">{{ getEmailAddressError() }}</mat-error>
                  </mat-form-field>
                </div>
                <div style="margin-bottom:10px">
                  <button mat-raised-button type="submit" style="margin-left:10px; margin-top:-20px" color="primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          <img src="../assets/images/logoOnly_green.png" style="height:80px">
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="container" style="margin-top:10px; margin-bottom:10px; font-weight:normal  ">
      <span><a href="https://twitter.com/BloomRail" target="_"><i class="fab fa-twitter fa-2x w3-hover-opacity"></i></a></span>
      <span style="margin-left:10px"><a href="https://www.linkedin.com/company/bloomrail" target="_"><i class="fab fa-linkedin fa-2x w3-hover-opacity"></i></a></span>
      <span fxFlex></span>
      <span class="link"><a href="assets/documents/policy/brPrivacyPolicy.pdf" target="_blank">Privacy Policy</a></span>
      <span class="link" style="margin-left:10px"><a href="assets/documents/policy/brTermsOfService.pdf" target="_blank">Terms & Conditions</a></span>
      <span style="margin-left:10px" matTooltip="{{ version }}">Copyright {{ today | date:'yyyy' }}</span>
    </div>
  </footer>
</div>
import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-info-restock',
  templateUrl: './info-restock.component.html',
  styleUrls: ['./info-restock.component.scss']
})
export class InfoRestockComponent implements OnInit {

  baseUrlPortal: string;

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.baseUrlPortal = environment.BASE_URL_PORTAL;

    this.seoService.updateSocialTags(
      'bloomRail FBA Inventory Forecaster', 
      'Make FBA inventory management simple with Inventory Forecaster.', 
      '/assets/images/icons/restock.png', 
      '/product-info/inventory-forecaster');
    
    this.seoService.updateDescription('bloomRail’s precision Inventory Forecaster helps you improve your operations while reducing your carbon footprint.');
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-inventory-challenges-solutions',
  templateUrl: './inventory-challenges-solutions.component.html',
  styleUrls: ['./inventory-challenges-solutions.component.scss']
})
export class InventoryChallengesSolutionsComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {

    if(this.route.snapshot.fragment) {
      const element = document.getElementById(this.route.snapshot.fragment);
      if(element) {
        setTimeout(() => {
          const yOffset = -63; // size of header
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({top: y, behavior: 'smooth'});
        }, 10);
      }
    }
  }

}

import { Injectable } from '@angular/core';

import { HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor(private appService: AppService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.appService.authenticated && req.url.indexOf('basicauth') === -1) {
      const authReq = req.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Basic ${window.btoa(this.appService.user.username + ":" + this.appService.user.password)}`, // used in non-AWS environments
          'brAuth': `Basic ${window.btoa(this.appService.user.username + ":" + this.appService.user.password)}` // needed for API gateway as it doesn't pass along Authorization header
        })
      });
      return next.handle(authReq);
    } else {
      return next.handle(req);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SEOService } from 'src/app/service/seo.service';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  billingPeriod: string;
  restockPrice100: number;
  restockPrice500: number;
  restockPrice1k: number;
  restockPrice10k: number;

  baseUrlPortal: string;

  constructor(private route: ActivatedRoute, private seoService: SEOService) { }

  ngOnInit(): void {
    this.seoService.updateSocialTags(
      'bloomRail Pricing', 
      'Interested enough to see how much it costs? Click here!', 
      '/assets/images/logoOnly_green.png', 
      '/pricing');
    
    this.seoService.updateDescription('bloomRail offers competitive pricing for our Inventory Forecaster. Click here to see what tier your business falls into.');

    this.baseUrlPortal = environment.BASE_URL_PORTAL;

    this.billingPeriod = 'monthly';
    this.restockPrice100 = 0;
    this.restockPrice500 = 49;
    this.restockPrice1k = 99;
    this.restockPrice10k = 189;

    /*
     * Okay this was a little ridiculous. Tried enabling anchorScrolling in app-routing.module.ts,
     * but all it ever did was scroll to the bottom of the page. Initial guess is that it's because
     * the page isn't fully rendered yet, so now we're manually reading in the fragment and even
     * just a small 10ms delay is enough for it to work. Obviously this doesn't scale across the
     * app.
     * 
     * Side note: behavior with anchorScrolling was different in FF/Chrome than it was in Safari.
     * Careful if messing with this in the future.
     */
    if(this.route.snapshot.fragment) {
      const element = document.getElementById(this.route.snapshot.fragment);
      if(element) {
        setTimeout(() => {element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});}, 10);
      }
    }
  }

  billingPeriodToggle() {
    this.setMonthlyRestockPrices(this.billingPeriod);
  }

  setMonthlyRestockPrices(billingPeriod: string) {
    if(this.billingPeriod == 'annual') {
      this.restockPrice100 = 19;
      this.restockPrice500 = 39;
      this.restockPrice1k = 79;
      this.restockPrice10k = 149;
    } else {
      this.restockPrice100 = 24;
      this.restockPrice500 = 49;
      this.restockPrice1k = 99;
      this.restockPrice10k = 189;
    }
  }

}

<div class="standardSideMargins">
  <section class="centerDiv" style="margin-bottom:20px">
    <form [formGroup]="surveyForm" (ngSubmit)="submit()">
      <div class="mat-headline centerText" style="margin-top:20px">What inventory challenges are you facing?</div>
      <div fxLayout="row" fxLayoutAlign="center start">
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <div>
            <div><mat-checkbox formControlName="stockouts">Stockouts/Lost Sales</mat-checkbox></div>
            <div class="checkbox-desc">e.g.: running out of stock too often, want to track lost sales</div>
          </div>
          <div>
            <div><mat-checkbox formControlName="supplyChain">Supply Chain Delays</mat-checkbox></div>
            <div class="checkbox-desc">e.g.: incorporating lead times, manufacturing delays</div>
          </div>
          <div>
            <div><mat-checkbox formControlName="storage">Storage</mat-checkbox></div>
            <div class="checkbox-desc">e.g.: storage limits and cost, stranded inventory</div>
          </div>
          <div>
            <div><mat-checkbox formControlName="dataComplexity">Data Complexity</mat-checkbox></div>
            <div class="checkbox-desc">e.g.: quantity of SKU's, manual retrievel and incorporation of reports</div>
          </div>
          <div>
            <div><mat-checkbox formControlName="futurePlanning">Future Planning</mat-checkbox></div>
            <div class="checkbox-desc">e.g.: demand planning, seasonality, outlier events, product launch, effect of ads/virality</div>
          </div>
        </div>
      </div>
      <div style="margin-top:10px" class="centerText">
        <button mat-raised-button color="primary" type="submit">Submit</button>
      </div>
    </form>
  </section>
</div>

import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-post20210627',
  templateUrl: './post20210627.component.html',
  styleUrls: ['./post20210627.component.scss']
})
export class Post20210627Component implements OnInit {

  baseUrlPortal: string;

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.baseUrlPortal = environment.BASE_URL_PORTAL;
    
    this.seoService.updateSocialTags(
      'brBlog: 3 Reasons Inventory Management Matters', 
      'Trouble keeping up with inventory demands? bloomRail explains how inventory management systems lead to robust e-commerce businesses', 
      '/assets/images/icons/container.png', 
      '/blog/posts/post-20210627-3-reasons-inventory-management-matters');
  }

}

<div>
  <section class="backgroundcolor-sky centerText" style="padding:30px 0px 30px 0px">
    <div id="headline" style="font-size:30px">Get in Touch</div>
    <div style="margin-top:20px; font-size:16px">We'd love to hear from you.</div>
  </section>
  <section class="backgroundcolor-sage centerText" style="padding-top:20px; padding-bottom:20px">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div class="centerText mat-headline">
        Like what you see but want to see more?
      </div>
      <div>
        Screenshots and text can't tell the entire story. Schedule a demo today!
      </div>
    <div style="margin-top:10px; width:70%">
        If there's a feature you're interested in that you don't see, feel free to request it! We're always looking to improve our software, and some of the functionality we've developed has come directly from requests that our users have made!
      </div>
    </div>
  </section>
  <section [style.display]="displayInputForm" style="margin-top:20px; margin-bottom:20px; width:50%" class="centerDiv">
    <form [formGroup]="contactForm" (ngSubmit)="submit()">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row" style="width:100%" fxLayout.lt-md="column">
          <div style="width:100%; margin-right:5px" [ngStyle.lt-md]="{'margin-right':'0px'}">
            <mat-form-field appearance="fill" style="width:100%">
              <mat-label>first name</mat-label>
              <input matInput formControlName="firstName" maxlength="50" required>
              <mat-error *ngIf="contactForm.get('firstName').invalid">{{ getRequiredFieldErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
          <div style="width:100%; margin-left:5px" [ngStyle.lt-md]="{'margin-left':'0px'}">
            <mat-form-field appearance="fill" style="width:100%">
              <mat-label>last name</mat-label>
              <input matInput formControlName="lastName" maxlength="50" required>
              <mat-error *ngIf="contactForm.get('lastName').invalid">{{ getRequiredFieldErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div style="width:100%">
          <mat-form-field appearance="fill" style="width:100%">
            <mat-label>company name</mat-label>
            <input matInput formControlName="companyName" maxlength="50">
          </mat-form-field>
        </div>
        <div style="width:100%">
          <mat-form-field appearance="fill" style="width:100%">
            <mat-label>email address</mat-label>
            <input matInput formControlName="emailAddress" maxlength="50" required>
            <mat-error *ngIf="contactForm.get('emailAddress').invalid">{{ getEmailAddressError() }}</mat-error>
          </mat-form-field>
        </div>
        <div style="width:100%">
          <mat-form-field appearance="fill" style="width:100%">
            <mat-label>subject</mat-label>
            <mat-select formControlName="subject">
              <mat-option *ngFor="let subject of allSubjects" [value]="subject">{{ subject }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div style="width:100%">
          <mat-form-field appearance="fill" style="width:100%">
            <mat-label>what's your question?</mat-label>
            <textarea matInput formControlName="messageContent" maxlength="1000"></textarea>
          </mat-form-field>
        </div>
        <div style="font-weight:600">
          Are you already an online seller?
        </div>
        <div style="margin-top:10px">
          <mat-radio-group formControlName="alreadyOnlineSeller" aria-label="Select an option">
            <mat-radio-button value="Yes">Yes</mat-radio-button>
            <mat-radio-button value="No">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div style="margin-top:20px; font-weight:600">
          What type of business are you?
        </div>
        <div style="margin-top:10px">
          <mat-radio-group formControlName="businessType" aria-label="Select an option">
            <mat-radio-button *ngFor="let businessType of allBusinessTypes" [value]="businessType">{{ businessType }}</mat-radio-button>
          </mat-radio-group>          
        </div>
        <div style="margin-top:20px; font-weight:600">
          What is your primary area of interest?
        </div>
        <div style="margin-top:10px">
          <mat-radio-group formControlName="areaOfInterest" aria-label="Select an option">
            <div class="centerText">
              <mat-radio-button *ngFor="let areaOfInterest of allAreasOfInterest" [value]="areaOfInterest">{{ areaOfInterest }}</mat-radio-button>
            </div>
          </mat-radio-group>          
        </div>
        <div style="margin-top:20px">
          <button mat-raised-button color="primary" type="submit" [disabled]="!contactForm.valid">Send Message</button>
        </div>
      </div>
    </form>
  </section>
  <section class="centerText" style="margin-top:20px" [style.display]="displayCompleteMessage">
    Thank you for reaching out! A bloomRail representative should contact you shortly.
  </section>
  <section class="centerText" style="margin-top:40px">
    <div class="mat-headline" style="margin-bottom:0px">Location</div>
    <div>Proudly founded in RVA</div>
    <div style="margin-top:10px">
      1717 East Cary Street<br/>
      Richmond, VA 23223
    </div>
    <div style="margin-top:20px">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d36458.95592946008!2d-77.45110734782244!3d37.54463618148308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1600815071699!5m2!1sen!2sus" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>
    <div style="margin-top:20px; margin-bottom:20px">
      <a href="mailto:contact@bloomrail.com">contact@bloomrail.com</a><br/>
      833-256-7245
    </div>
  </section>
</div>
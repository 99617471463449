import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private meta: Meta) { }

  updateSocialTags(title: string, description: string, image: string, url: string) {
    let baseURLWeb = environment.BASE_URL_WEB;

    this.meta.addTag({property: 'og:title', content: title});
    this.meta.addTag({property: 'og:description', content: description});
    this.meta.addTag({property: 'og:image', content: baseURLWeb+image});
    this.meta.addTag({property: 'og:url', content: baseURLWeb+url});

    this.meta.addTag({name: 'twitter:title', content: title});
    this.meta.addTag({name: 'twitter:description', content: description});
    this.meta.addTag({name: 'twitter:image', content: baseURLWeb+image});
    this.meta.addTag({name: 'twitter:card', content: 'summary_large_image'});

    this.updateDescription(description);
  }

  updateKeywords(tags: string) {
    this.meta.addTag({property: 'keywords', content: tags});
  }

  updateDescription(description: string) {
    this.meta.addTag({property: 'description', content: description});
  }
}

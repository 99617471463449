import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-post20211011',
  templateUrl: './post20211011.component.html',
  styleUrls: ['./post20211011.component.scss']
})
export class Post20211011Component implements OnInit {

  baseUrlPortal: string;

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.baseUrlPortal = environment.BASE_URL_PORTAL;
    
    this.seoService.updateSocialTags(
      'brBlog: AARRR - Pirate Metrics for Ecommerce Analytics', 
      'Using AARRR analytics (aka Pirate Metrics) can create sustainable growth for your brand for years to come.', 
      '/assets/images/blog/2021/aarrrFunnel.jpg', 
      '/blog/posts/post-20211011-AARRR-pirate-metrics-for-ecommerce-analytics');
  }

}

<section class="centerText" style="background-color:#BF5027; color: white; padding-top:20px; padding-bottom:20px">
  <h1 class="mat-display-1" style="margin-bottom:0px">Our Team</h1>
  <div>Simplifying e-commerce</div>
</section>
<section fxLayout="row" fxLayoutAlign="center center" style="margin-top:20px">
  <div class="centerText" style="margin-right:10px">
    <mat-card>
      <img src="../../../../assets/images/staff/allenLHL.jpg" style="height:200px"/>
      <div class="name brandFont">Allen Rogers</div>
    </mat-card>
    <div style="margin-top:10px">Cofounder & CEO</div>
  </div>
  <div class="centerText" style="margin-left:10px">
    <mat-card>
      <img src="../../../../assets/images/staff/willLHL.jpg" style="height:200px"/>
      <div class="name brandFont">Will Hunt</div>
    </mat-card>
    <div style="margin-top:10px">Cofounder & CTO</div>
  </div>
</section>
<section style="margin-top:40px">
  <h2 class="centerText mat-display-1 color-primary" style="margin-bottom:10px">
    Meet the Founders
  </h2>
  <div class="centerDiv" style="width:50%">
    <p>
      Allen Rogers and Will Hunt founded bloomRail in 2020 in an effort to build easy-to-use yet powerful software solutions for online sellers. Brothers-in-law, they saw an opportunity to join their complementary skills and knowledge to bring unique value to the space.
    </p>
    <p>
      Rogers spent half a decade in Seattle with Amazon, helping Enterprise organizations integrate processes and systems with Amazon’s B2B marketplace. Before pivoting to the tech industry, he worked in the education and nonprofit spaces in Austin. He is an avid writer, runner, and backyard gardener.
    </p>
    <p>
      Hunt has been a technologist for over two decades, specializing in technical solution design and implementation for complex data migrations and multi-faceted system integrations. He is chiefly responsible for developing product technology and backend infrastructure, as well as never missing an Arsenal match and supplying his motorsports group chat with animated GIFs.
    </p>
    <p>
      Rogers and Hunt have spent their careers working to make others’ lives easier. With bloomRail, they dedicate every day to simplifying ecommerce for their customers.
    </p>
    <p>
      #simplify #ecommerce #growforward
    </p>
  </div>
  <div class="centerText" style="margin-top:20px; margin-bottom:40px">
    <div>
      Learn more about how bloomRail can help.
    </div>
    <div style="margin-top:10px">
      <button mat-raised-button routerLink="/contact">Contact Us</button>
    </div>
  </div>
</section>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a-w52-zw50b3',
  templateUrl: './a-w52-zw50b3.component.html',
  styleUrls: ['./a-w52-zw50b3.component.scss']
})
export class AW52ZW50b3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

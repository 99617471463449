import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

@Component({
  selector: 'app-post20210330',
  templateUrl: './post20210330.component.html',
  styleUrls: ['./post20210330.component.scss']
})
export class Post20210330Component implements OnInit {

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.seoService.updateSocialTags(
      'brBlog: Top 5 Musts When You Start a Shopify Store', 
      'Shopify has become the leading e-commerce platform on the web, boasting over 800,000 online stores. Its key to success: the ease...', 
      '/assets/images/services/logoShopify.png', 
      '/blog/posts/post-20210330-5-musts-when-you-start-a-shopify-store');
  }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inventory-challenges',
  templateUrl: './inventory-challenges.component.html',
  styleUrls: ['./inventory-challenges.component.scss']
})
export class InventoryChallengesComponent implements OnInit {

  surveyForm: FormGroup;
  selectionMapping: Map<string, string>;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.surveyForm = new FormGroup({
      stockouts: new FormControl(false),
      supplyChain: new FormControl(false),
      storage: new FormControl(false),
      dataComplexity: new FormControl(false),
      futurePlanning: new FormControl(false),
    });
    
    // Column order for 'matrix': Stockouts/Lost Sales, Supply Chain Delays, Storage, Data Complexity, Future Planning
    // We don't need Stockouts/Lost Sales as no matter what, if that is checked, we go to Lost Sales section.
    this.selectionMapping = new Map<string, string>();
    this.selectionMapping.set('true false false false', 'supplyChain'); // whitepaper?
    this.selectionMapping.set('false true false false', 'overstockAndStorage');
    this.selectionMapping.set('false false true false', 'automation');
    this.selectionMapping.set('false false false true', ''); // just load page, no scrolling
    this.selectionMapping.set('true true false false', 'overstockAndStorage');
    this.selectionMapping.set('true false true false', 'automation');
    this.selectionMapping.set('true false false true', ''); // just load page, no scrolling
    this.selectionMapping.set('false true true false', 'automation');
    this.selectionMapping.set('false true false true', ''); // just load page, no scrolling
    this.selectionMapping.set('false false true true', 'automation');
    this.selectionMapping.set('true true true false', 'automation');
    this.selectionMapping.set('false true true true', 'automation');
    this.selectionMapping.set('true true true true', 'automation');
    this.selectionMapping.set('true true false true', 'lostSales');
    this.selectionMapping.set('true false true true', 'automation');
  }

  submit() {
    let stockouts: boolean = this.surveyForm.get('stockouts').value;
    let supplyChain: boolean = this.surveyForm.get('supplyChain').value;
    let storage: boolean = this.surveyForm.get('storage').value;
    let dataComplexity: boolean = this.surveyForm.get('dataComplexity').value;
    let futurePlanning: boolean = this.surveyForm.get('futurePlanning').value;

    let fragment: string;
    if(stockouts == true) {
      fragment = 'lostSales';
    } else {
      let stringToMatch = supplyChain + ' ' + storage + ' ' + dataComplexity + ' ' + futurePlanning;
      fragment = this.selectionMapping.get(stringToMatch);
    }

    if(fragment != '') {
      this.router.navigateByUrl('inventory-challenges-solutions#' + fragment);
    } else {
      this.router.navigateByUrl('inventory-challenges-solutions');
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent implements OnInit {

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.seoService.updateSocialTags(
      "bloomRail's brBlog", 
      'General (usually e-commerce related) musings from our staff.', 
      '/assets/images/bloomRail_Tag_RGB_green.png', 
      '/blog/list');
  }

}

<section class="blogMargins" [ngStyle.lt-md]="{'width':'70%'}">
    <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom:20px">
      <div>
        <img src="../../../../../../assets/images/logoOnly_green.png" style="width:20px"/>
      </div>
      <div class="mat-title" style="margin-bottom:0px; margin-left:5px">brBlog</div>
    </div>
    <h1 class="mat-display-1" style="margin-bottom:5px">Seeking Clarity – How Businesses Have Adjusted for Covid-19</h1>
    <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - May 4, 2021</div>
    <div class="blogBody">
      <p>
        Little more than a year ago, a global pandemic turned our world upside down. In an instant, even the most standard activities were halted. Many people became afraid to see loved ones, go to school, or report to essential jobs. Stay-at-home orders and nationwide lockdowns affected every country. Stores, restaurants, and malls were shuttered—some permanently. 
      </p>
      <p>
        A survey conducted by <a href="https://www.pnas.org/content/117/30/17656" target="_blank">PNAS</a> of 5,800 small businesses as early as the week of March 28 to April 4, 2020 showed businesses had already suffered drastic setbacks due to uncertainty from government officials in containing the spread of COVID. Of the businesses studied, active employment had already been reduced by 39%. It was an even larger drop in the Mid-Atlantic region, where 54% of businesses closed and employment dropped by 47%.
      </p>
      <p>
        In-person retail was one of the most drastically affected markets. Due to stay-at-home orders and social distancing precautions, many stores closed while adapting to accommodate for a mysterious airborne virus with catastrophic effects. Many companies were able to implement solutions for consumers to shop or participate in events online, while some were not able to make the shift. Significant reductions in foot traffic from prior years continues today.
      </p>
      <p>
        According to BBC, US companies saw a 20% drop in foot traffic from January 2020 to January 2021, while countries with more stringent lockdowns, like Germany and the UK, saw much sharper declines—97% and 78% respectively. Even with the transition to online ordering, about a third of consumers have been squeezed and have spent less than previous years. 
      </p>
      <p>
        The hotel and travel industries were hardest hit due to the uncertainty of COVID. Many companies have gone bankrupt and let go of millions of employees. The number of daily commercial flights remains drastically low compared to 2019 and early 2020 and it is predicted the industry will not fully recover until 2025. But with the vaccine rollout taking effect and large portions of the population normalizing best practices for reducing spread, there has been a recent uptick in ticket sales.
      </p>
      <p>
        Fourteen months later, businesses and their patrons work to return to a semblance of pre-pandemic life. However, many know there is an altered reality, and the real work is an ongoing adjustment to a new normal.
      </p>
      <p>
        As life and expectations normalize, businesses aim to see improvement and remain hopeful for the future. While many businesses will not have weathered the storm, entrepreneurs and executives alike must survey the landscape in a quest for new opportunities.
      </p>
      <p>
        Many industries predict significant increases in sales when the pandemic has been quelled. To get there, it is essential for everyone to do their part in following spread prevention best practices and getting vaccinated when possible.
      </p>
      <p>
        If you are looking for more information on how to pivot, launch, or grow your business, you can reach us at <a href="mailto:contact@bloomrail.com">contact@bloomrail.com</a>. 
      </p>
    </div>
  </section>
import { Component, HostListener } from '@angular/core';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { AppService } from './service/app.service';

import { Idle } from '@ng-idle/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { FormControl, Validators } from '@angular/forms';
import { BloomRestService } from './service/bloom-rest.service';
import { MatSnackBar } from '@angular/material/snack-bar';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title:string = 'bloomRail-ang';

  currentRoute:string;
  headerBgClass:string = 'headerBgColorHome';
  padContent:string = '';

  version: string;
  baseUrlPortal: string;
  isProduction: boolean;

  today: Date;

  emailAddress: FormControl;

  constructor(public appService: AppService, private router: Router, private activatedRoute: ActivatedRoute, 
    private titleService: Title, private bloomRestService: BloomRestService, private snackBar: MatSnackBar) {
    
  }

  ngOnInit() {
    this.version = environment.VERSION;
    this.baseUrlPortal = environment.BASE_URL_PORTAL;
    this.isProduction = environment.production;
    this.today = new Date();

    this.emailAddress = new FormControl(null, {validators: [Validators.required, Validators.email]});

    AOS.init();

    // Change header background dynamically (currently none on home page,
    // slate everywhere else).
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const eventUrl = /(?:\/)([^#]+)(?=#|$)/.exec(event.urlAfterRedirects);
        const currentRoute = (eventUrl || []).join('');
        if(currentRoute && currentRoute != 'home') {
          this.currentRoute = currentRoute;
          this.headerBgClass = 'headerBgColorSolid';
          this.padContent = 'padForHeader';
        } else {
          this.currentRoute = currentRoute;
          this.headerBgClass = 'headerBgColorTransparent';
          this.padContent = '';
        }

        let title: string = 'bloomRail';

        let child = this.activatedRoute.firstChild;
        if(child.snapshot.data['title']) {
          title = child.snapshot.data['title'];
          this.titleService.setTitle(child.snapshot.data['title'])
        }
        this.titleService.setTitle(title);

        if(this.isProduction == true) {
          gtag('js', new Date());
          gtag('config', 'UA-180942691-1', 
            {
              'page_path': event.urlAfterRedirects
            }
          );
        }
      }
    });
  }

  emailSubscription() {
    if(this.emailAddress.value && this.emailAddress.valid) {
      this.bloomRestService.addNewsletterSubscription(this.emailAddress.value).subscribe(data => {
        this.snackBar.open('Thank you for subscribing!', 'Close', {
          duration: 3000,
        });
      });
    }
  }

  getEmailAddressError() {
    let control = this.emailAddress;
    if(control.hasError('required')) {
      return this.getRequiredFieldErrorMessage();
    }
    if(control.hasError('email')) {
      return 'Please enter a valid email address.';
    }
    if(control.hasError('server-error')) {
      return control.getError('server-error');
    }
  }

  getRequiredFieldErrorMessage():string {
    return 'Required field.';
  }

  logout() {
    this.appService.logout();
    this.router.navigateByUrl('/');
  }

  /**
   * Just used to fade the header background based on the user scrolling to and from the
   * top of the page. Transparent by default on Home, colored on all other pages.
   */
  @HostListener("window:scroll", [])
  onWindowScroll() {
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 50) {
      this.headerBgClass = 'headerBgColorTranslucent';
    } else if (number < 50) {
      if(this.currentRoute && this.currentRoute != 'home') {
        this.headerBgClass = 'headerBgColorSolid';
      } else {
        this.headerBgClass = 'headerBgColorTransparent';
      }
    }
  }
}
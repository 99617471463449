<section class="blogMargins" [ngStyle.lt-md]="{'width':'70%'}">
  <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom:20px">
    <div>
      <img src="../../../../../../assets/images/logoOnly_green.png" style="width:20px"/>
    </div>
    <div class="mat-title" style="margin-bottom:0px; margin-left:5px">brBlog</div>
  </div>
  <h1 class="mat-display-1" style="margin-bottom:5px">Domestic Manufacturing for Resilient Supply Chain Management</h1>
  <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - February 9, 2022</div>
  <div class="blogBody">
    <p>
      We’ve all seen <a href="https://www.msn.com/en-us/money/markets/why-the-supply-chain-is-in-crisis-spurring-an-everything-shortage/ar-AAPNu8J" target="_blank">the news lately</a> about global supply chain issues due to the lingering effects of covid-19 and the surge in consumer demand as we recover from it. Manufacturing your e-commerce products domestically in the United States rather than overseas is worth discussing, as it can create more long-term resilience in your supply chain and help your brand thrive when global issues arise. For decades now, the emphasis has been on creating the lowest-cost manufacturing possible via offshoring, which can be great for profit margins in the short-term, but, when <a href="https://www.wsj.com/articles/supply-chain-issues-car-chip-shortage-covid-manufacturing-global-economy-11633713877" target="_blank">problems like we’re seeing today</a> arise within a deeply complex global supply chain with many moving parts, they are much more difficult to fix. <a href="https://www.bluegreenalliance.org/resources/wonk-wednesday-how-we-can-support-domestic-manufacturing-and-fight-climate-change-by-investing-in-industrial-transformation/">Domestic manufacturing</a> may cost more up front (for now), but it simplifies and protects your supply chain from uncontrollable factors, creating strong resilience for your e-commerce brand in times of crisis. With experts warning that supply chain-induced shortages could <a href="https://www.cnbc.com/2021/10/18/supply-chain-chaos-is-hitting-global-growth-and-could-get-worse.html">get worse</a> and <a href="https://www.businessinsider.com/executives-say-brace-for-shipping-delays-price-hikes-next-year-2021-9" target="_blank">continue into 2023</a>
    </p>
    <div>
      <ol>
        <li>
          <div style="font-weight:500">
            Domestic Manufacturing Insulates Your E-commerce Business from Compounded Global Supply Chain Issues
          </div>
          <div>
            This <a href="https://www.msn.com/en-us/money/other/a-look-at-the-care-bears-journey-through-the-broken-supply-chain/vi-AAPeZ4P" target="_blank">short video about Chinese-made Care Bears toys</a> provides a good introduction into the global supply chain issues American sellers are facing. Currently, it is taking twice as long and costing much more to deliver these good-hearted fuzzy pals. Delays can lead to frustrated customers and a worsened brand image for retailers (including E-commerce retailers), and the increased shipping expenses can hurt both you and the consumers to whom you pass them along. As we mentioned previously, these complex supply chain issues are only getting worse, and they are <a href="https://www.cnbc.com/2021/10/18/supply-chain-chaos-is-hitting-global-growth-and-could-get-worse.html" target="_blank">not going to let up</a> any time soon. Global supply chains are extremely complex, with many moving parts. After covid-19 <a href="https://www2.deloitte.com/global/en/pages/risk/cyber-strategic-risk/articles/covid-19-managing-supply-chain-risk-and-disruption.html" target="_blank">wreaked havoc on these (mostly China-dependent) systems</a>, it is almost impossible to get all of the different elements of the supply chain back up to speed at the same time to handle the influx of consumer demand as vaccines help US consumers recover and spend: "The world economy is out of sync because parts of it were forced to go offline when the pandemic started and getting all the industry players back in line at the same time is near impossible,” <a href="https://news.yahoo.com/why-supply-chain-crisis-spurring-165541269.html" target="_blank">according to Harry Broadman</a>, a former US trade negotiator. Moving towards a more domestic supply chain management approach can give you more direct control and vision into a simpler chain, allowing for adjustments to be made much more quickly. It’s worth considering in today’s climate. 
          </div>
        </li>
        <li style="margin-top:20px">
          <div style="font-weight:500">
            Economies of Scale Can Return To Domestic Manufacturing, if Enough Companies Get on Board
          </div>
          <div>
            There is no doubt that manufacturing products overseas has created lower initial costs over the past few decades, and the monumental shift away from domestic manufacturing created strong economies of scale for international supply chains, leaving domestic supply chains in the dust. However, in 2021, with the global supply chain crisis <a href="https://thehill.com/opinion/international/578074-with-global-supply-chain-problems-escalating-start-your-holiday" target="_blank">here and only getting worse</a>, more and more companies are considering moving towards a more domestic supply and manufacturing system. As this trend continues, economies of scale could rise back up around domestic manufacturing, lowering expenses and allowing it to better compete with offshoring. 
          </div>
        </li>
        <li style="margin-top:20px">
          <div style="font-weight:500">
            Environmental Impact and Branding
          </div>
          <div>
            We’re focusing on improving the resilience and sustainability of supply chains here, but the long-term effects of moving towards domestic manufacturing can also lessen your company’s <a href="https://www.bluegreenalliance.org/resources/wonk-wednesday-how-we-can-support-domestic-manufacturing-and-fight-climate-change-by-investing-in-industrial-transformation/" target="_blank">environmental impact</a> (from <a href="https://www.rodongroup.com/blog/7-benefits-of-manufacturing-in-the-us#:~:text=Environmentally%20Friendly,a%20much%20eco%2Dfriendlier%20option.&text=By%20manufacturing%20at%20home%20and,sure%20to%20grow%20your%20business." target="_blank">ocean-shipping’s large carbon footprint</a>, for example), allowing for effective and responsible green marketing while also appealing to customers who want to support US workers by buying US-made products. There are <a href="https://prototype-shortrun.com/american-wire-edm/domestic-vs-offshore/" target="_blank">countless factors to explore</a> when it comes to considering domestic manufacturing vs offshore manufacturing, and bloomRail is here to help support your decision-making process. To learn more about optimizing your supply chain with accurate restock forecasting, Contact Us today.
          </div>
        </li>
      </ol>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div><a mat-raised-button routerLink="/contact">Contact bloomRail</a></div>
      <div class="mat-caption" style="margin-top:10px">
        Learn More
      </div>
    </div>
  </div>
</section>
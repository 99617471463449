import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { AppService } from './../app.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorReporter implements ErrorHandler {

  constructor(private injector: Injector) { }

  handleError(error: Error | HttpErrorResponse) {

    const appService = this.injector.get(AppService);

    if (error instanceof HttpErrorResponse) {
      // Server Error
      // Handled by server-error-interceptor service, debugging here just in case.
      appService.displaySnackbarError('Unexpected error (NGSS-01); please try again later.');
      appService.reportError('NGSS-01', error);
    } else {
      // Client Error
      appService.displaySnackbarError('Unexpected error (NGCS-01); please try again later.');
      appService.reportError('NGCS-01', error);
    }

    throw(error);
  }

}

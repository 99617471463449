<section>
  <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-md="column" style="width:90%" class="centerDiv">
    <div style="margin:20px 20px 20px 20px" data-aos="fade-up" data-aos-duration="1000">
      <img src="../../../../../assets/images/icons/twohouses.png" style="width:300px"/>
    </div>
    <div style="margin-right:20px; width:60%; max-width:500px" data-aos="fade-down" data-aos-duration="1000">
      <div class="mat-display-1" style="margin-bottom:0px">Build for Scale</div>
      <div>Tap into exponential growth at 10x the purchase volume of traditional D2C channels with a focus on B2B</div>
    </div>
  </div>
</section>
<section>
  <mat-card class="centerDiv" style="max-width:500px">
    <div class="centerText">
      <div class="mat-title" style="margin-bottom:0px">bloomRail</div>
      <div class="mat-title">B2B Package</div>
    </div>
    <p>
      At bloomRail, we are experts in B2B. Our B2B package will put you on track for long-term success with an enhanced
      focus on features that allow you to target business customers and expand your B2B channel.
    </p>
    <ul>
      <li>Business & Bulk Order Pricing</li>
      <li>RFQ Management</li>
      <li>B2B Reporting</li>
    </ul>
    <div class="centerText" style="margin-top:20px">
      <button mat-raised-button routerLink="/pricing" fragment="solutions">Learn More</button>
    </div>
  </mat-card>
</section>
<section class="backgroundcolor-sky" style="margin-top:40px; padding-top:20px; padding-bottom:20px" data-aos="flip-up" data-aos-duration="1000">
  <div class="centerDiv" style="width:50%" [ngStyle.lt-md]="{'width':'90%'}">
    <div class="brandFont centerText" style="font-size:20px">Supported Channels</div>
    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-top:20px">
      <img src="../../../../assets/images/services/logoAmazon.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
      <img src="../../../../assets/images/services/logoShopify.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
      <img src="../../../../assets/images/services/logoWalmart.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
      <img src="../../../../assets/images/services/logoWoo.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
      <img src="../../../../assets/images/services/logoEbay.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
    </div>
  </div>
</section>
<section style="margin-top:40px">
  <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-md="column" style="width:90%" class="centerDiv">
    <div style="max-width:500px; margin:20px 20px 20px 20px" data-aos="fade-right" data-aos-duration="1000">
      <div class="mat-headline">
        Stay in-stock for bulk ordering from business buyers
      </div>
      <div>
        <ul>
          <li>Plan for seasonal demand</li>
          <li>Forecast for long-term needs</li>
          <li>Track inbound shipments</li>
          <li>Don't miss bulk orders!</li>
        </ul>
      </div>
      <div class="centerText">
        <a mat-raised-button routerLink="/product-info/inventory-forecaster">Try our <span style="font-weight:bold">Inventory Forecaster</span></a>
        <div class="mat-caption" style="margin-top:5px">30-day free trial</div>
      </div>
    </div>
    <div style="margin:20px 20px 20px 20px" data-aos="flip-up" data-aos-duration="1000">
      <img src="../../../../../assets/images/icons/restock.png" style="width:300px"/> 
    </div>
  </div>
</section>

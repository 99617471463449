import { Component, OnInit } from '@angular/core';

import { FormControl,Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BloomRestService } from '../../../service/bloom-rest.service';
import { SEOService } from 'src/app/service/seo.service';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  emailAddress: FormControl;

  baseUrlPortal: string;

  constructor(private bloomRestService: BloomRestService, private snackBar: MatSnackBar, private seoService: SEOService) { }

  ngOnInit(): void {
    this.baseUrlPortal = environment.BASE_URL_PORTAL;

    this.emailAddress = new FormControl(null, {validators: [Validators.required, Validators.email]});

    this.seoService.updateSocialTags(
      'bloomRail', 
      'e-commerce Solution Provider and proud curator of our FBA Inventory Manager tool.', 
      '/assets/images/logoOnly_green.png', 
      '');
    
    this.seoService.updateDescription('Improve your business’s sustainability, reduce wasted costs, and increase sales with logistics optimization.');
  }

  emailSubscription() {
    if(this.emailAddress.value && this.emailAddress.valid) {
      this.bloomRestService.addNewsletterSubscription(this.emailAddress.value).subscribe(data => {
        this.snackBar.open('Thank you for subscribing!', 'Close', {
          duration: 3000,
        });
      });
    }
  }

  getEmailAddressError() {
    let control = this.emailAddress;
    if(control.hasError('required')) {
      return this.getRequiredFieldErrorMessage();
    }
    if(control.hasError('email')) {
      return 'Please enter a valid email address.';
    }
    if(control.hasError('server-error')) {
      return control.getError('server-error');
    }
  }

  getRequiredFieldErrorMessage():string {
    return 'Required field.';
  }

}

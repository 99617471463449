import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-post20210708',
  templateUrl: './post20210708.component.html',
  styleUrls: ['./post20210708.component.scss']
})
export class Post20210708Component implements OnInit {

  baseUrlPortal: string;

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.baseUrlPortal = environment.BASE_URL_PORTAL;
    
    this.seoService.updateSocialTags(
      'brBlog: What Is Gumroad?', 
      'Gumroad provides a simple way for artists and creators to share and sell useful products & knowledge and get paid for their work', 
      '/assets/images/companyLogos/gumroad-logo.png', 
      '/blog/posts/post-20210708-what-is-gumroad');
  }

}

import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-post20211025',
  templateUrl: './post20211025.component.html',
  styleUrls: ['./post20211025.component.scss']
})
export class Post20211025Component implements OnInit {

  baseUrlPortal: string;

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.baseUrlPortal = environment.BASE_URL_PORTAL;
    
    this.seoService.updateSocialTags(
      'brBlog: Retargeting To Boost ROI for Online Advertising', 
      'What Is Retargeting? We all do it: folks browse products and services all the time without buying, both IRL and online.', 
      '/assets/images/blog/2021/engine_ss.png', 
      '/blog/posts/post-20211025-retargeting-to-boost-roi-for-online-advertising');
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/public/home/home.component';
import { SmbComponent } from './components/public/services/smb/smb.component';
import { EnterpriseComponent } from './components/public/services/enterprise/enterprise.component';
import { B2bComponent } from './components/public/services/b2b/b2b.component';
import { ContactComponent } from './components/public/contact/contact.component';
import { PricingComponent } from './components/public/pricing/pricing.component';
import { HowToRegisterComponent } from './components/public/how-to-register/how-to-register.component';
import { InfoRestockComponent } from './components/public/productInfo/info-restock/info-restock.component';
import { MissionComponent } from './components/public/mission/mission.component';
import { OurTeamComponent } from './components/public/our-team/our-team.component';
import { PartnersComponent } from './components/public/partners/partners.component';
import { InventoryChallengesComponent } from './components/public/landing/inventory-challenges/inventory-challenges.component';
import { InventoryChallengesSolutionsComponent } from './components/public/landing/inventory-challenges-solutions/inventory-challenges-solutions.component';

import { HttpInterceptorService } from './service/interceptors/http-interceptor.service';
import { ServerErrorInterceptorService } from './service/interceptors/server-error-interceptor.service';
import { ErrorReporter } from './service/error/error-reporter';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { BlogListComponent } from './components/public/blog/blog-list/blog-list.component';
import { Post20201010Component } from './components/public/blog/posts/post20201010/post20201010.component';
import { Post20201012Component } from './components/public/blog/posts/post20201012/post20201012.component';
import { Post20201130Component } from './components/public/blog/posts/post20201130/post20201130.component';
import { Post20210330Component } from './components/public/blog/posts/post20210330/post20210330.component';
import { Post20210504Component } from './components/public/blog/posts/post20210504/post20210504.component';
import { Post20210627Component } from './components/public/blog/posts/post20210627/post20210627.component';
import { Post20210708Component } from './components/public/blog/posts/post20210708/post20210708.component';
import { Post20211011Component } from './components/public/blog/posts/post20211011/post20211011.component';
import { Post20211025Component } from './components/public/blog/posts/post20211025/post20211025.component';
import { Post20211123Component } from './components/public/blog/posts/post20211123/post20211123.component';
import { Post20220202Component } from './components/public/blog/posts/post20220202/post20220202.component';
import { Post20220209Component } from './components/public/blog/posts/post20220209/post20220209.component';

import { NewsroomComponent } from './components/public/press/newsroom/newsroom.component';
import { PressRelease20201022Component } from './components/public/press/articles/press-release20201022/press-release20201022.component';

import { AW52ZW50b3Component } from './components/test/a-w52-zw50b3/a-w52-zw50b3.component';

import { FlexLayoutModule } from '@angular/flex-layout';

// Per documentation, for general fraud detection across entire site,
// import in main module.
import '@stripe/stripe-js';
import { ScullyLibModule } from '@scullyio/ng-lib';


@NgModule({
    declarations: [
        AppComponent,
        HowToRegisterComponent,
        HomeComponent,
        InfoRestockComponent,
        ContactComponent,
        SmbComponent,
        EnterpriseComponent,
        B2bComponent,
        MissionComponent,
        OurTeamComponent,
        PartnersComponent,
        PricingComponent,
        BlogListComponent,
        Post20201012Component,
        Post20201010Component,
        Post20201130Component,
        Post20210330Component,
        Post20210504Component,
        Post20210627Component,
        Post20210708Component,
        Post20211011Component,
        Post20211025Component,
        Post20211123Component,
        Post20220202Component,
        Post20220209Component,
        NewsroomComponent,
        PressRelease20201022Component,
        AW52ZW50b3Component,
        InventoryChallengesComponent,
        InventoryChallengesSolutionsComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatDividerModule,
        MatDialogModule,
        MatIconModule,
        MatMenuModule,
        MatBadgeModule,
        MatTooltipModule,
        MatToolbarModule,
        MatListModule,
        MatCardModule,
        MatButtonToggleModule,
        MatGridListModule,
        MatRadioModule,
        MatSnackBarModule,
        MatTabsModule,
        MatCheckboxModule,
        FlexLayoutModule,
        ScullyLibModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorInterceptorService,
            multi: true
        },
        { provide: ErrorHandler,
            useClass: ErrorReporter
        },
        DecimalPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export class User {

  username:string;
  password:string;
  firstName:string;
  lastName:string;
  phoneNumber:string;
  passwordExpiration:Date;
  accountExpiration:Date;

  registrationType:string;

  merchantId:string;
  company:string;
  stripeCustId:string;
  accountFbaInventoryMgrDataCurrent:boolean;

  preferences: Array<Preference>;
  subscriptions: Array<BrSubscription>;
}

export class Preference {
  id:number;
  preferenceName:string;
  preferenceValue:string;
}

export class BrSubscription {
  id:number;
  subscriptionType:string;
  subscriptionStatus:string;
  currentPeriodEnd:Date;
}
import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

@Component({
  selector: 'app-post20201012',
  templateUrl: './post20201012.component.html',
  styleUrls: ['./post20201012.component.scss']
})
export class Post20201012Component implements OnInit {

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.seoService.updateSocialTags(
      'brBlog: Planning for long-term growth in e-commerce', 
      'If you have a product to sell and an internet connection, you can launch an online store. Imagine your successful e-commerce business...', 
      '/assets/images/icons/arrowSign.png', 
      '/blog/posts/post-20201012-planning-for-long-term-growth-in-e-commerce');
  }

}

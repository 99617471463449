import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss']
})
export class OurTeamComponent implements OnInit {

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.seoService.updateSocialTags(
      'Our Team', 
      "Get to know bloomRail's founders better.", 
      '/assets/images/logoOnly_green.png', 
      '/our-team');
    
    this.seoService.updateDescription("bloomRail's co-founders have an extensive history in both sustainable business practices as well as technical solutions. Learn more here.");
  }

}

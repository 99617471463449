<div class="standardSideMargins">
  <section style="margin-top:20px">
    <div fxLayout="column" fxLayoutAlign="center center" style="width:60%; margin:0 auto;">
      <h2 class="mat-display-1 color-primary" style="margin-bottom:10px">
        Mission
      </h2>
      <div>
        At bloomRail, our mission is to help e-commerce sellers achieve sustainable, long-term growth with our simple yet powerful analytics software solutions and professional services.
      </div>
    </div>
  </section>
  <section style="width:50%; margin:0 auto; margin-top:20px">
    <div fxLayout="column" fxLayoutAlign="center center">
      <h2 class="mat-display-1 color-sky" style="margin-bottom:10px">Our Philosophy</h2>
      <div>
        <ol style="margin-top:0px; margin-bottom:0px">
          <li>
            <span class="color-primary">Be helpful and do good work</span><br/>
            If you add value and make people's lives easier, you will find success.
          </li>
          <li style="margin-top:10px">
            <span class="color-sky">Believe</span><br/>
            Given the correct tools and information, everyone has the potential to grow their business into a robust, sustainable operation.
          </li>
          <li style="margin-top:10px">
            <span class="color-rust">Deliver Results</span><br/>
            Give people the tools and information they require.
          </li>
        </ol>
      </div>
    </div>
  </section>
  <section style="width:60%; margin:0 auto; margin-top:20px">
    <div fxLayout="column" fxLayoutAlign="center center">
      <h2 class="mat-display-1 color-rust" style="margin-bottom:10px">What We Do</h2>
      <div>
        <p>Our Inventory Forecaster software allows Amazon FBA sellers to perform deep analysis, helping them understand their inventory health, predict future in-stock demand, and avoid aging inventory fees. Users can set the parameters of their analysis based on their unique business needs, and do it all at the click of a button!</p>
        <p>We also offer out-of-the-box or custom solution packages for launching and scaling e-commerce channels to support anyone looking to set their business up for healthy, long-term growth.</p>
      </div>
    </div>
  </section>
  <section style="margin-top:60px; margin-bottom:40px">
    <div fxLayout="row" fxLayoutAlign="space-evenly center">
      <div class="centerText">
        <div>
          <div class="mat-headline">Inventory Forecaster</div>
          <div><img src="../../../../assets/images/icons/restock.png" style="width:200px"/></div>
          <div><button mat-raised-button routerLink="/product-info/inventory-forecaster">Start your Free trial</button></div>
        </div>
      </div>
      <div class="centerText">
        <div>
          <div class="mat-headline">Solution Packages</div>
          <div><img src="../../../../assets/images/icons/ladder.png" style="width:200px"/></div>
          <div><button mat-raised-button routerLink="/pricing" fragment="solutions">Pick your Plan</button></div>
        </div>
      </div>
    </div>
  </section>
</div>
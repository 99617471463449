import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-how-to-register',
  templateUrl: './how-to-register.component.html',
  styleUrls: ['./how-to-register.component.scss']
})
export class HowToRegisterComponent implements OnInit {

  baseUrlPortal: string;

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.seoService.updateSocialTags(
      'Learn how to Register with bloomRail', 
      'With only a few simple steps, bloomRail can pull your sales/inventory history and be up and running in no time.', 
      '/assets/images/logoOnly_green.png', 
      '/how-to-register');
    
    this.seoService.updateDescription('With only a few simple steps, bloomRail can pull your sales/inventory history and be up and running in no time. Learn what is needed here.');

    this.baseUrlPortal = environment.BASE_URL_PORTAL;
  }

  scrollToAuthorize() {
    const element = document.getElementById('authorize');
      if(element) {
        setTimeout(() => {element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});}, 10);
      }
  }

}

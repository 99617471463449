<div style="width:600px; margin:0 auto">
  <div class="centerText smallerHeaderPadding">
    <h1 class="mat-headline" style="margin-bottom:10px">How To Register</h1>
    <div style="font-size:16px; margin-top:10px">Wondering how to sign-up and use our tools?</div>
    <div style="margin-top:30px">It's simple.</div>
  </div>
  <mat-card style="margin-top:10px">
    <div class="centerText">
      Go to our <a href="{{ baseUrlPortal }}/create-account" target="_blank">Create Account</a> page. Fill out the simple form to create a user profile.
    </div>
    <div>
      <div class="centerText" style="margin-top:10px">
        Check your email and use the Verification Code sent to complete registration.<br/>
        <img src="../../../../assets/images/tutorials/how-to-register/verificationCodeForm.png" style="width:100%"/>
      </div>
    </div>
  </mat-card>
  <mat-card style="margin-top:10px">
    <div>
      <div class="centerText mat-headline" style="margin-bottom:0px">What to do Next</div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <div>
          If you are signing up for Services or one of our e-commerce Solution Packages, YOU’RE ALL SET, and a representative will be in touch with you shortly!
        </div>
        <div><img src="../../../../assets/images/icons/ladder.png" style="width:100px; margin-left:10px"/></div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <div><img src="../../../../assets/images/icons/restock.png" style="width:100px; margin-right:10px"/></div>
        <div>
          If you are signing up for our Inventory Forecaster, go to <a href="https://sellercentral.amazon.com" target="_blank">https://sellercentral.amazon.com</a>, log-in to your Seller Account, and follow these easy steps:
        </div>
      </div>
    </div>
  </mat-card>
  <mat-card style="margin-top:10px">
    <div>
      <div class="mat-title centerText" style="margin-bottom:0px">To grant bloomRail Amazon User Permissions</div>
      <div>
        Go to Settings -> User Permissions<br/>
        Under <span style="text-decoration: underline">Add a New User</span> section:<br/>
        Name: bloomrail<br/>
        Email Address: admin@bloomrail.com
      </div>
      <mat-divider style="margin-top:10px; margin-bottom:10px" [inset]="true"></mat-divider>
      <div>
        This allows us to view your account, help make changes, manage data, and access some UI-based reports. It’s helpful for making sure your account is healthy!
      </div>
      <div style="margin-top:10px">
        <img src="../../../../assets/images/tutorials/how-to-register/amzManagePermissions.png" style="width:100%"/>
        <div class="mat-caption">*Click the Manage Permissions button for our account (in the right hand column under Current Users after we’ve been added) to grant the level of permissions you see fit.*</div>
      </div>
    </div>
  </mat-card>
  <mat-card style="margin-top:10px; margin-bottom:30px">
    <div>
      <div class="mat-title centerText" style="margin-bottom:0px">To grant bloomRail Amazon Developer Permissions</div>
      <div>
        Go to Settings -> User Permissions<br/>
        Click <span style="font-style: italic">Visit Manage Your Apps</span> button.
      </div>
      <div><img src="../../../../assets/images/tutorials/how-to-register/amzVisitManageAppsButton.png" style="width:100%"/></div>
      <mat-divider style="margin-top:10px; margin-bottom:10px" [inset]="true"></mat-divider>
      <div>Click <span style="text-decoration: italic">Authorize new developer</span>.</div>
      <div><img src="../../../../assets/images/tutorials/how-to-register/amzAuthNewDeveloper.png" style="width:300px"/></div>
      <mat-divider style="margin-top:10px; margin-bottom:10px" [inset]="true"></mat-divider>
      <div>
        Enter:<br/>
        Developer's name: bloomRail<br/>
        Developer ID: 420239448789
      </div>
      <div style="margin-top:5px"><img src="../../../../assets/images/tutorials/how-to-register/amzEnterDeveloperInfo.png" style="width:100%"/></div>
      <mat-divider style="margin-top:10px; margin-bottom:10px" [inset]="true"></mat-divider>
      <div id="authorize" style="scroll-margin-top:64px">Authorize</div>
      <div style="margin-top:5px"><img src="../../../../assets/images/tutorials/how-to-register/amzAuthorizeStep.png" style="width:100%"/></div>
      <mat-divider style="margin-top:10px; margin-bottom:10px" [inset]="true"></mat-divider>
      <div>Send us the MWS Auth Token you receive to <a href="mailto:admin@bloomrail.com">admin@bloomrail.com</a>.</div>
      <div>
        <p>BINGO! We're in!</p>
        <p>Next up, gives us a minute (aka 1-3 business days) to get your account set-up and create your dashboard.</p>
        <p>We'll let you know when it's all set, and you can log-in and analyze away!</p>
        <div>#simplify #ecommerce</div>
      </div>
    </div>
  </mat-card>
  <!-- <mat-card style="margin-top:10px; margin-bottom:30px">
    <div>
      <div class="mat-title centerText" style="margin-bottom:0px">Find Us in the Amazon Appstore</div>
      <p>
        That’s right—we’re a bona fide Amazon 3P App Developer. You can find us in the <a href="https://sellercentral.amazon.com/apps/store/ref=xx_masdis_dnav_xx" target="_blank">Appstore</a> and just click through to grant us Developer access.
      </p>
      <div>
        <div>It goes just like this:</div>
        <div>Log-in to Seller Central and go to <span style="font-style:italic">Apps & Services -> Discover Apps</span> in the menu bar.</div>
        <div><img src="../../../../assets/images/tutorials/how-to-register/amzDiscoverApps.png"/></div>
        <mat-divider style="margin-top:10px; margin-bottom:10px" [inset]="true"></mat-divider>
        <span style="font-style:italic">Search the Appstore</span> for bloomRail.<br/>
        <img src="../../../../assets/images/tutorials/how-to-register/amzAppSearch.png"/><br/>
        <mat-divider style="margin-top:10px; margin-bottom:10px" [inset]="true"></mat-divider>
        <div>Click on our App & Authorize us</div>
        <div style="margin-top:5px">
          <img src="../../../../assets/images/tutorials/how-to-register/amzBrAuthorize1.png" style="width:30%"/>
          <img src="../../../../assets/images/tutorials/how-to-register/amzBrAuthorize2.png" style="width:70%"/>
        </div>
        <div style="margin-top:5px">And execute the <a href="javascript:void(0)" (click)="scrollToAuthorize()">Authorize</a> steps from above.</div>
        <mat-divider style="margin-top:10px; margin-bottom:10px" [inset]="true"></mat-divider>
      </div>
      <p>
        Just like that, you're on your way to a simpler life (well, at least some inventory analysis work, anyway).
      </p>
      <div>
        <p>
          If you have any questions or trouble with self-registration, <a href="mailto:contact@bloomrail.com">Contact Us</a> right away or call 833-256-7245.
        </p>
        <div>#growforward</div>
      </div>
    </div>
  </mat-card> -->
</div>
import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

@Component({
  selector: 'app-post20210504',
  templateUrl: './post20210504.component.html',
  styleUrls: ['./post20210504.component.scss']
})
export class Post20210504Component implements OnInit {

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.seoService.updateSocialTags(
      'brBlog: Seeking Clarity – How Businesses Have Adjusted for Covid-19', 
      'Little more than a year ago, a global pandemic turned our world upside down. In an instant, even the most standard activities...', 
      '/assets/images/icons/virus.jpg', 
      '/blog/posts/post-20210504-seeking-clarity-how-businesses-have-adjusted-for-covid-19');
  }

}

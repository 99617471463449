<section class="centerText" style="margin-top:40px" data-aos="fade-down" data-aos-duration="500">
  <h1 class="mat-display-2" style="margin-bottom:20px">
    Fast-track Pricing
  </h1>
  <div>Clear, simple pricing. Easy to register. No credit card required.</div>
  <div><img src="../../../../assets/images/icons/restock.png" style="width:200px"/></div>
  <div class="mat-headline">Inventory Forecaster</div>
</section>
<section class="centerText" style="margin-bottom:40px">
  <!-- <div>
    <mat-button-toggle-group [(value)]="billingPeriod" (click)="billingPeriodToggle()" data-aos="flip-up" data-aos-duration="500">
      <mat-button-toggle value="annual">Annually</mat-button-toggle>
      <mat-button-toggle value="monthly">Monthly</mat-button-toggle>
    </mat-button-toggle-group>
  </div> -->
  <div fxLayout="row" fxLayoutAlign="center stretch" style="margin-top:20px">
    <mat-card class="planPadding" style="margin:5px 5px 5px 5px" data-aos="fade-right" data-aos-duration="500">
      <div class="rfName">Forecaster 100</div>
      <div class="rfCost">${{ restockPrice100 }}<span class="rfUnit">/mo</span></div>
      <div class="rfCaption">up to 100 orders<br/>per month</div>
    </mat-card>
    <mat-card class="planPadding" style="margin:5px 5px 5px 5px" data-aos="fade-down" data-aos-duration="500">
      <div class="rfName">Forecaster 500</div>
      <div class="rfCost">${{ restockPrice500 }}<span class="rfUnit"></span>/mo</div>
      <div class="rfCaption">up to 500 orders<br/>per month</div>
    </mat-card>
    <mat-card class="planPadding" style="margin:5px 5px 5px 5px" data-aos="fade-up" data-aos-duration="500">
      <div class="rfName">Forecaster 1k</div>
      <div class="rfCost">${{ restockPrice1k }}<span class="rfUnit"></span>/mo</div>
      <div class="rfCaption">up to 1,000 orders<br/>per month</div>
    </mat-card>
    <mat-card class="planPadding" style="margin:5px 5px 5px 5px" data-aos="fade-left" data-aos-duration="500">
      <div class="rfName">Forecaster 10k</div>
      <div class="rfCost">${{ restockPrice10k }}<span class="rfUnit"></span>/mo</div>
      <div class="rfCaption">up to 10,000 orders<br/>per month</div>
    </mat-card>
  </div>
  <div style="margin-top:20px">
    More than 10k orders a month? Nice job! Click <a href="../../../../assets/documents/subscriptionPlans/bloomrail_pricing_2022_02pdf.pdf">here</a> for our full fee table.
  </div>
  <div style="margin-top:20px" data-aos="zoom-in" data-aos-duration="500">
    <a mat-raised-button href="{{ baseUrlPortal }}/create-account">Start your Free Trial</a>
  </div>
</section>
<!-- <section id="solutions">
  <div class="centerText">
    <div><img src="../../../../assets/images/icons/boxLightbulb.png" style="width:300px"/></div>
    <div class="mat-headline">Solution Packages</div>
    <div class="centerDiv" style="width:60%; text-align:left">
      With our pre-built e-commerce solution packages, we will be with you every step of the way--from launch to enterprise-level optimization. Pick your package and get on the right track!
    </div>
  </div>
  <div style="margin-top:20px" fxLayout="row" fxLayoutAlign="center center">
    <mat-card>
      <table style="border-collapse:collapse">
        <tr class="brandFont">
          <th></th>
          <th class="headerPadding">
            <div class="packageName">Launch Plan</div>
            <div class="packageRate">2.89%</div>
            <div class="packagePeriod">annual fee</div>
            <div class="packageCaption">up to 100 orders per month</div>
          </th>
          <th class="headerPadding">
            <div class="packageName">Growth Plan</div>
            <div class="packageRate">3.29%</div>
            <div class="packagePeriod">annual fee</div>
            <div class="packageCaption">up to 500 orders per month</div>
          </th>
          <th class="headerPadding">
            <div class="packageName">B2B Plan</div>
            <div class="packageRate">3.99%</div>
            <div class="packagePeriod">annual fee</div>
            <div class="packageCaption">up to 1,000 orders per month</div>
          </th>
        </tr>
        <tr style="background-color: #005978; color:white;">
          <td class="subHeader firstColumn" style="font-size:18px; padding-right:0px">Features</td>
          <td class="subHeader" style="font-size:14px">Single-Channel</td>
          <td class="subHeader" style="font-size:14px">Multi-Channel</td>
          <td class="subHeader" style="font-size:14px">Multi-Channel</td>
        </tr>
        <tr>
          <td class="firstColumn">Market Analysis Report</td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">
            <div>
              <div>Product Creation</div>
              <div class="subFeature">up to 250 products</div>
            </div>
          </td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">Inventory Syncing</td>
          <td>n/a</td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">Automated Repricing</td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">Order Management</td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">Account Quality Metric</td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">User Acceptance Testing</td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">Quarterly Review</td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">Enhanced Brand Content</td>
          <td></td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">Fraud Prevention</td>
          <td></td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">
            <div>
              <div>Storefront Creation</div>
              <div class="subFeature">based on Marketplace availability</div>
            </div>
          </td>
          <td></td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">
            <div>
              <div>Account Management</div>
              <div class="subFeature">2 hours per month</div>
            </div>
          </td>
          <td></td>
          <td><mat-icon>check</mat-icon></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">
            <div>
              <div>Business Pricing</div>
              <div class="subFeature">where available</div>
            </div>
          </td>
          <td></td>
          <td></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">
            <div>
              <div>Quantity Discounts</div>
              <div class="subFeature">where available</div>
            </div>
          </td>
          <td></td>
          <td></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">
            <div>
              <div>Manage Quotes</div>
              <div class="subFeature">where applicable</div>
            </div>
          </td>
          <td></td>
          <td></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
        <tr>
          <td class="firstColumn">B2B Reporting</td>
          <td></td>
          <td></td>
          <td><mat-icon>check</mat-icon></td>
        </tr>
      </table>
    </mat-card>
  </div>
  <div class="centerText" style="margin-top:20px; margin-bottom:40px">
    <a mat-raised-button href="{{ baseUrlPortal }}/create-account">Register Now</a>
  </div>
</section> -->
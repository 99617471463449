<section class="backgroundcolor-sky" data-aos="flip-up" data-aos-duration="1000">
  <div class="sideMargins" style="padding-top:40px; padding-bottom:40px">
    <div class="mat-headline color-primary">Custom-fit to your needs</div>
    <div>
      Our comprehensive Partner Network allows us to find the exact tools and services your business needs to stay on track and grow.
      Receive a free analysis to learn how we can help.
    </div>
    <div class="centerText">
      <button mat-raised-button routerLink="/contact" style="margin-top:20px">Contact Us</button>
    </div>
  </div>
</section>
<section class="sideMargins">
  <div class="centerText" data-aos="flip-down" data-aos-duration="1000">
    <img src="../../../../assets/images/icons/diagram.png" style="width:50%"/>
  </div>
  <div data-aos="flip-left" data-aos-duration="1000">
    <div class="mat-headline color-primary">Simplify e-commerce</div>
    <div>
      At bloomRail, this is our top priority. That’s why partners are a cornerstone of our business.  We analyze your specific business needs and produce 
      an exact solution fit using the best products and services available on the market.
    </div>
  </div>
</section>
<section class="standardSideMargins centerText" style="margin-top:40px; margin-bottom:40px" data-aos="flip-right" data-aos-duration="1000">
  <div class="mat-title color-primary">Here are a few of our Partners</div>
  <div class="centerDiv" style="width:75%">
    <mat-grid-list cols="2" rowHeight="2:1">
      <mat-grid-tile><img src="../../../../assets/images/companyLogos/channelkey.png" style="width:50%"></mat-grid-tile>
      <mat-grid-tile><img src="../../../../assets/images/companyLogos/essendant.jpg" style="width:50%"></mat-grid-tile>
      <mat-grid-tile><img src="../../../../assets/images/companyLogos/sellics.jpg" style="width:75%"></mat-grid-tile>
      <mat-grid-tile><img src="../../../../assets/images/companyLogos/junglescout.png" style="width:60%"></mat-grid-tile>
    </mat-grid-list>
  </div>
  <button mat-raised-button routerLink="/contact">Join our Partner Network</button>
</section>
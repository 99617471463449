<section class="blogMargins" [ngStyle.lt-md]="{'width':'70%'}">
  <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom:20px">
    <div>
      <img src="../../../../../../assets/images/logoOnly_green.png" style="width:20px"/>
    </div>
    <div class="mat-title" style="margin-bottom:0px; margin-left:5px">brBlog</div>
  </div>
  <h1 class="mat-display-1" style="margin-bottom:5px">Top 5 Musts When You Start a Shopify Store</h1>
  <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - March 30, 2021</div>
  <div class="blogBody">
    <p>
      Shopify has become the <a href="https://www.shopify.com/blog/start-online-store" target="_blank">leading e-commerce platform on the web</a>, boasting over 800,000 online stores. Its key to success: the ease in which you can launch and manage a storefront. The company has gained massive traction due to its focus on supporting small businesses; giving users more bandwidth to sell rather than being forced to navigate the complexities of e-commerce. 
    </p>
    <p>
      Historically, creating your own online store could take days, weeks, or months to build, depending on its complexity. While creating both an elegant and functional store still takes time and care, Shopify has mastered the fundamental architecture of a professionalized, launch-ready shop.
    </p>
    <p>
      According to <a href="https://www.shopify.com/blog/start-online-store" target="_blank">their blog</a>, here are the top 5 things every seller should focus on when creating a Shopify store.
    </p>
    <p>
      <span style="font-weight:500;">Pick the Right Template</span><br/>
      Picking a template seems like a simple task but a lot can go into it. You must think about how you want your brand to be represented. The template you choose provides the look and feel for your store and is the first thing customers notice when viewing a product page.
    </p>
    <p>
      The good news: you can always change your selected template, so if your first choice does not hit the mark, don’t be afraid to mix it up. Shopify offers a handful of free templates as well as numerous <a href="https://themes.shopify.com/" target="_blank">professionally designed themes</a> available for purchase.
    </p>
    <p>
      <span style="font-weight:500;">How to Add Products</span><br/>
      This might seem obvious, but adding a detailed description of your product is essential. Keep it short but also capture everything the customer needs to know.
    </p>
    <p>
      Product photos should be elegant and hi-res. A simple one color backdrop makes products look professional and streamlined, and white background makes them easily mappable to marketplaces like Amazon and Wal-Mart. 
    </p>
    <p>
      <span style="font-weight:500;">Understand Your Inventory Analytics</span><br/>
      Unlike most marketplaces, Shopify lets you choose to keep selling a product even if it is <a href="https://help.shopify.com/en/manual/online-sales-channels/wholesale/channel/orders/out-of-stock-orders" target="_blank">out of stock</a>. The downside: you run the risk of customer dissatisfaction with delivery times and expectations.
    </p>
    <p>
      On the other hand, showing a product as sold out and unavailable for purchase can hurt discoverability and make it harder to drive SEO, which can slow down growth.
    </p>
    <p>
      Using <a routerLink="/product-info/inventory-forecaster">inventory analytics tools for your e-commerce business</a> can be helpful in this regard. It is essential to keep inventory in stock and delivered on time so you will be known as a reliable seller.
    </p>
    <p>
      <span style="font-weight:500;">Create Pages</span><br/>
      Adding pages to your site so customers understand what your company values is vital in creating a viable Shopify store. Pages such as FAQs, company history/story, and contact email/customer service will allow for a more personal connection with the customer. These pages can address customers' concerns and can save you time and money.<br/>
      Note: narrow what is important to your company and brand as it can be overwhelming to have too many pages. 
    </p>
    <p>
      <span style="font-weight:500;">Ask for Feedback</span><br/>
      When creating your store, it is important to be transparent and open with your customers. Having them feel like they can be heard can be a great tool in gaining traction for your company.  Create a page that <a href="https://www.shopify.com/retail/how-to-encourage-customer-reviews-and-why-you-should" target="_blank">allows users to share</a> how you can improve and you will gain valuable insights into how to grow as a brand and company.
    </p>
    <br/>
    <p>
      Choosing the correct platform to start your online store can be a daunting task. You must understand which things to focus on for each unique marketplace. It is essential to diversify your customer base and make it convenient for your product to be accessible. Knowing the key features of the platform you choose and focusing on them from the start can set your company apart and set your business up for sustainable, long-term growth.
    </p>
    <p>
      To learn more about launching, scaling, and optimizing your e-commerce business, visit us at <a routerLink="/services/smb">bloomrail.com/services/smb</a>
    </p>
  </div>
</section>
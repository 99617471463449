<section class="blogMargins" [ngStyle.lt-md]="{'width':'70%'}">
  <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom:20px">
    <div>
      <img src="../../../../../../assets/images/logoOnly_green.png" style="width:20px"/>
    </div>
    <div class="mat-title" style="margin-bottom:0px; margin-left:5px">brBlog</div>
  </div>
  <h1 class="mat-display-1" style="margin-bottom:5px">Retargeting To Boost ROI for Online Advertising</h1>
  <div style="margin-bottom:20px"><span style="font-weight:450">by bloomRail Staff</span> - October 25, 2021</div>
  <div class="blogBody">
    <p>
      By some <a href="https://www.marketo.com/ebooks/website-and-seo-for-lead-generation/" target="_blank">estimated averages</a>, only 4% of visitors to an e-commerce store are ready to buy: 96% of your visitors are just browsing and could come back later--but only if they remember it! The natural and effective response to this frightening statistic is to focus much of your online advertising on previously-engaged consumers, via retargeting strategies. Let’s get into how it works.
    </p>
    <p class="centerText mat-headline">
      What Is Retargeting?
    </p>
    <p>
      We all do it: <a href="https://retargeter.com/blog/a-brief-introduction/" target="_blank">folks browse products and services all the time without buying</a>, both IRL and online. If we engage with an ad, visit an e-commerce website for a while, and leave, we have at least some <a href="https://www.adroll.com/learn-more/retargeting" target="_blank">interest in becoming a customer someday</a>. Retargeting previous visitors with ads keeps your brand and products or services in front of members of your target-market long after they’ve left your website, nudging them to reconsider buying, while increasing subconscious brand-recognition and familiarity. Retargeting the same groups of potential buyers with online ads works well and <a href="https://instapage.com/blog/what-is-retargeting" target="_blank">increases ROI</a> for e-commerce marketing, because these groups have already shown interest in your products. If a potential customer engaged with an ad or your website in the past but didn’t convert into a buyer, they are much more likely to re-engage and buy later than a brand-new visitor. <a href="https://www.oberlo.com/videos/ecommerce-terms-to-know-before-you-start" target="_blank">Retargeting</a> means advertising again to those folks, and <a href="https://www.g2.com/categories/retargeting" target="_blank">retargeting software</a> is the tool used to do it. 
    </p>
    <p class="centerText mat-headline">
      Two Retargeting Methods
    </p>
    <div>
      <ol>
        <li>
          <span style="font-weight:bold">Retargeting with Pixels and Cookies: </span>Keeping track of who has visited your website to retarget them with ads on other sites requires using <a href="https://learnwebanalytics.com/whats-the-difference-between-a-cookie-a-pixel-and-a-tag/" target="_blank">pixels and cookies</a>. A pixel is a piece of <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank">Javascript</a> code on your website or ad-click landing page. The pixel will drop a cookie into your visitor’s browser, telling it to remember they visited your site. Your pixel can communicate with <a href="https://ads.google.com/home/how-it-works/" target="_blank">Google ads</a>, <a href="https://www.facebook.com/business/tools/ads-manager?content_id=sL2lIsTU43BvLYJ&ref=sem_smb&utm_source=GOOGLE&utm_medium=fbsmbsem&utm_campaign=PFX_SEM_G_BusinessAds_US_EN_Brand_Exact_Desktop_Top&utm_content=Ads_Evaluating&kenid=_k_Cj0KCQjwwY-LBhD6ARIsACvT72PNry7lFOG0ec_gn0lhzNzpKJ1XY3h6FbFPwqCukQCmeJ-ZtSxBD5saAoA4EALw_wcB_k_&utm_term=facebook%20ads&utm_ct=EVG&gclid=Cj0KCQjwwY-LBhD6ARIsACvT72PNry7lFOG0ec_gn0lhzNzpKJ1XY3h6FbFPwqCukQCmeJ-ZtSxBD5saAoA4EALw_wcB" target="_blank">Facebook ads</a>, <a href="https://business.linkedin.com/marketing-solutions/cx/17/06/advertise-on-linkedin" target="_blank">Linkedin ads</a>, and other <a href="https://www.adpushup.com/blog/the-best-ad-networks-for-publishers/" target="_blank">online advertising networks</a>, letting them know to retarget the visitors with your cookie. This guarantees that your online advertising is getting onto the screens of members of your target-market who have already shown interest. You can also get sophisticated and customize ads for groups of users who previously engaged with your site in certain ways, spent more time on your site, clicked a certain link, looked at a specific product, etc. Pixels and cookies are paramount for most retargeting strategies.
        </li>
        <li>
          <span style="font-weight:bold">Retargeting with Manual Lists: </span>List-based retargeting is often referred to as remarketing. (Note: pixel/cookie-based retargeting is sometimes called remarketing, as well. Don’t worry: semantics don’t matter for your ROI and bottom line.) This method involves email lists you have collected of customers and visitors who are interested in your products and services. For example, you can upload your email-newsletter list to your <a href="https://www.g2.com/categories/retargeting" target="_blank">retargeting platform</a> and use it to target folks who have already submitted their email addresses. This method can be useful for using ads to keep your brand in the consciousness of your newsletter-subscribers who haven’t bought anything, your free-trial subscribers whom you want to upgrade to a paid version, previous buyers whom you’d like to turn into repeat-customers to increase <a href="https://en.m.wikipedia.org/wiki/Customer_lifetime_value" target="_blanks">CLV</a>, etc. However, unlike pixels and cookies, this method misses out on folks who have engaged with your ads and/or visited your site but haven’t signed up for anything.
        </li>
      </ol>
    </div>
    <p>
      There are many ways to approach the creation of retargeting ad campaigns. The detailed instructions <a href="https://instapage.com/blog/what-is-retargeting" target="_blank">here</a> for setting up retargeting ads with Google AdWords and Facebook are a nice introduction, but there are copious avenues and possibilities, and the level of sophistication of your retargeting will affect your long-term marketing ROI in concrete ways, leading to sustainable growth for your business. If you want to learn more about e-commerce strategy for your specific business, hit us up below; we love to nerd out about this stuff.
    </p>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div><a mat-raised-button routerLink="/contact">Contact bloomRail</a></div>
      <div class="mat-caption" style="margin-top:10px">
        Learn More
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" style="margin-top:30px">
      <div class="centerText" style="font-size:24px; line-height:30px; width:70%">
        <a routerLink="/product-info/inventory-forecaster" style="font-weight:600">Inventory Forecaster</a> will put your business on track for long-term, sustainable growth.
      </div>
      <div style="margin-top:20px"><a mat-raised-button href="{{ baseUrlPortal }}/create-account">Try it today for FREE</a></div>
      <div style="margin-top:5px; font-size:10px">No credit card required at registration</div>
    </div>
  </div>
</section>
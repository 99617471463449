import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-post20211123',
  templateUrl: './post20211123.component.html',
  styleUrls: ['./post20211123.component.scss']
})
export class Post20211123Component implements OnInit {

  baseUrlPortal: string;

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.baseUrlPortal = environment.BASE_URL_PORTAL;
    
    this.seoService.updateSocialTags(
      'brBlog: 4 Low-Cost Ecommerce Marketing Strategies', 
      'For an ecommerce entrepreneur running a ​​small, medium, or large online business, there are many types of marketing strategies to consider.', 
      '/assets/images/blog/2021/digital-marketing-with-a-megaphone-concept-flat-design.jpg', 
      '/blog/posts/post-20211123-4-low-cost-ecommerce-marketing-strategies');
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/public/home/home.component';
import { SmbComponent } from './components/public/services/smb/smb.component';
import { EnterpriseComponent } from './components/public/services/enterprise/enterprise.component';
import { B2bComponent } from './components/public/services/b2b/b2b.component';
import { ContactComponent } from './components/public/contact/contact.component';
import { PricingComponent } from './components/public/pricing/pricing.component';
import { HowToRegisterComponent } from './components/public/how-to-register/how-to-register.component';
import { InfoRestockComponent } from './components/public/productInfo/info-restock/info-restock.component';
import { MissionComponent } from './components/public/mission/mission.component';
import { OurTeamComponent } from './components/public/our-team/our-team.component';
import { PartnersComponent } from './components/public/partners/partners.component';
import { InventoryChallengesComponent } from './components/public/landing/inventory-challenges/inventory-challenges.component';
import { InventoryChallengesSolutionsComponent } from './components/public/landing/inventory-challenges-solutions/inventory-challenges-solutions.component';

import { BlogListComponent } from './components/public/blog/blog-list/blog-list.component';
import { Post20201010Component } from './components/public/blog/posts/post20201010/post20201010.component';
import { Post20201012Component } from './components/public/blog/posts/post20201012/post20201012.component';
import { Post20201130Component } from './components/public/blog/posts/post20201130/post20201130.component';
import { Post20210330Component } from './components/public/blog/posts/post20210330/post20210330.component';
import { Post20210504Component } from './components/public/blog/posts/post20210504/post20210504.component';
import { Post20210627Component } from './components/public/blog/posts/post20210627/post20210627.component';
import { Post20210708Component } from './components/public/blog/posts/post20210708/post20210708.component';
import { Post20211011Component } from './components/public/blog/posts/post20211011/post20211011.component';
import { Post20211025Component } from './components/public/blog/posts/post20211025/post20211025.component';
import { Post20211123Component } from './components/public/blog/posts/post20211123/post20211123.component';
import { Post20220202Component } from './components/public/blog/posts/post20220202/post20220202.component';
import { Post20220209Component } from './components/public/blog/posts/post20220209/post20220209.component';

import { NewsroomComponent } from './components/public/press/newsroom/newsroom.component';
import { PressRelease20201022Component } from './components/public/press/articles/press-release20201022/press-release20201022.component';

import { AW52ZW50b3Component } from './components/test/a-w52-zw50b3/a-w52-zw50b3.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: {title: 'bloomRail'} },
  { path: 'services/smb', component: SmbComponent, data: {title: 'bloomRail - SMB Services'} },
  { path: 'services/enterprise', component: EnterpriseComponent, data: {title: 'bloomRail - Enterprise Business Services'} },
  { path: 'services/b2b', component: B2bComponent, data: {title: 'bloomRail - Business to Business Services'} },
  { path: 'product-info/inventory-forecaster', component: InfoRestockComponent, data: {title: 'bloomRail - Inventory Forecaster'} },
  { path: 'about', component: MissionComponent, data: {title: 'bloomRail - About'} },
  { path: 'mission', component: MissionComponent, data: {title: 'bloomRail - About'} },
  { path: 'our-team', component: OurTeamComponent, data: {title: 'bloomRail - Our Team'} },
  { path: 'contact', component: ContactComponent, data: {title: 'bloomRail - Contact'} },
  { path: 'partners', component: PartnersComponent, data: {title: 'bloomRail - Partners'} },
  { path: 'pricing', component: PricingComponent, data: {title: 'bloomRail - Product and Service Pricing'} },
  { path: 'how-to-register', component: HowToRegisterComponent, data: {title: 'bloomRail - How to Register'} },
  { path: 'inventory-challenges', component: InventoryChallengesComponent, data: {title: 'bloomRail - Inventory Challenges'}},
  { path: 'inventory-challenges-solutions', component: InventoryChallengesSolutionsComponent, data: {title: 'bloomRail - Inventory Challenges'}},

  { path: 'blog/list', component: BlogListComponent, data: {title: 'bloomRail Blog - List'} },
  { path: 'blog/posts/post-20201010', component: Post20201010Component, data: {title: 'bloomRail Blog - Be Helpful and Do Good Work'} },
  { path: 'blog/posts/post-20201010-be-helpful-and-do-good-work', component: Post20201010Component, data: {title: 'bloomRail Blog - Be Helpful and Do Good Work'} },
  { path: 'blog/posts/post-20201012', component: Post20201012Component, data: {title: 'bloomRail Blog - Planning for Long Term Growth in e-commerce'} },
  { path: 'blog/posts/post-20201012-planning-for-long-term-growth-in-e-commerce', component: Post20201012Component, data: {title: 'bloomRail Blog - Planning for Long Term Growth in e-commerce'} },
  { path: 'blog/posts/post-20201130-5-rules-for-picking-high-quality-vendors-for-your-e-commerce-business', component: Post20201130Component, data: {title: 'bloomRail Blog - 5 Rules for Picking High Quality Vendors for Your e-commerce Business'} },
  { path: 'blog/posts/post-20210330-5-musts-when-you-start-a-shopify-store', component: Post20210330Component, data: {title: 'bloomRail Blog - 5 Musts When You Start a Shopify Store'} },
  { path: 'blog/posts/post-20210504-seeking-clarity-how-businesses-have-adjusted-for-covid-19', component: Post20210504Component, data: {title: 'bloomRail Blog - Seeking Clarity: How Businesses Have Adjusted for COVID-19'} },
  { path: 'blog/posts/post-20210627-3-reasons-inventory-management-matters', component: Post20210627Component, data: {title: 'bloomRail Blog - 3 Reasons Inventory Management Matters'} },
  { path: 'blog/posts/post-20210708-what-is-gumroad', component: Post20210708Component, data: {title: 'bloomRail Blog - What is Gumroad?'} },
  { path: 'blog/posts/post-20211011-AARRR-pirate-metrics-for-ecommerce-analytics', component: Post20211011Component, data: {title: 'AARRR - Pirate Metrics for Ecommerce Analytics'} },
  { path: 'blog/posts/post-20211025-retargeting-to-boost-roi-for-online-advertising', component: Post20211025Component, data: {title: 'Retargeting To Boost ROI for Online Advertising'} },
  { path: 'blog/posts/post-20211123-4-low-cost-ecommerce-marketing-strategies', component: Post20211123Component, data: {title: '4 Low-Cost Ecommerce Marketing Strategies'} },
  { path: 'blog/posts/post-20220202-environmental-sustainability-is-good-for-the-e-commerce-bottom-line', component: Post20220202Component, data: {title: 'Environmental Sustainability Is Good for the E-Commerce Bottom Line'} },
  { path: 'blog/posts/post-20220209-domestic-manufacturing-for-resilient-supply-chain-management', component: Post20220209Component, data: {title: 'Domestic Manufacturing for Resilient Supply Chain Management'} },

  { path: 'press/newsroom', component: NewsroomComponent, data: {title: 'bloomRail - Newsroom'} },
  { path: 'press/articles/2020-10-22-press-release-bloomrail-launch', component: PressRelease20201022Component, data: {title: 'bloomRail News - bloomRail Launch'} },

  { path: 'product-info/aW52ZW50b3', component: AW52ZW50b3Component, data: {title: 'bloomRail - Page Not Found'} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    // anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

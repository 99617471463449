<section class="backgroundcolor-primary" style="color:white">
  <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-md="column" style="width:90%" [ngStyle.lt-md]="{'width':'100%'}" class="centerDiv">
    <div style="margin-right:20px; width:60%; max-width:500px" [ngStyle.lt-md]="{'width':'90%','margin-right':'0px'}" data-aos="fade-down" data-aos-duration="1000">
      <div class="mat-display-1" style="margin-bottom:0px" [ngStyle.lt-md]="{'margin-top':'40px','font-size':'24px'}">
        Make inventory management simple with <span style="font-weight:600">Inventory Forecaster</span>
      </div>
      <div [ngStyle.lt-md]="{'font-size':'16px'}">
        <ul>
          <li>Improve your seller profile and automatically scale</li>
          <li>Drive more sales through optimization</li>
          <li>Avoid wasteful overage fees</li>
        </ul>
      </div>
    </div>
    <div style="margin:20px 20px 20px 20px" data-aos="fade-up" data-aos-duration="1000">
      <img src="../../../../../assets/images/icons/restock.png" style="width:300px"/>
    </div>
  </div>
</section>
<section style="margin-top:30px; margin-left:auto; margin-right:auto; width:90%;">
  <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-md="column">
    <div style="max-width:600px; padding:15px 0px 15px 0px;" data-aos="fade-right" data-aos-duration="1000">
      <mat-card>
        <img src="../../../../../assets/images/productInfo/inventoryMgrRecommendations2.png" style="width:100%"/>
      </mat-card>
    </div>
    <div style="padding:0px 20px 0px 20px" data-aos="fade-left" data-aos-duration="1000">
      <div class="mat-title">
        Now with a powerful, user-driven algorithm that produces custom-fit, highly actionable recommendations.
      </div>
      <div>
        Inventory management is a complicated task. Let bloomRail optimize your supply chain and simplify your life by giving you accurate restock recommendations that you can easily understand and customize to fit your needs.
      </div>
    </div>
  </div>
  <div class="centerText" style="margin-top:20px; margin-bottom:10px">
    <a mat-raised-button style="padding:10px 20px 10px 20px; font-size:24px" color="primary" href="{{ baseUrlPortal }}/create-account">Try it for free</a>
  </div>
</section>
<section class="backgroundcolor-sage" style="margin-top:30px; padding-top:30px; padding-bottom:30px">
  <div fxLayout="row" fxLayoutAlign="center start" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
    <div fxLayout="column" fxLayoutAlign="center center" style="padding:0px 10px 0px 10px" data-aos="fade-right" data-aos-duration="500">
      <div class="centerText mat-subheading-2">Easily customize for your Needs</div>
      <div>
        <mat-card style="height:300px">
          <img src="../../../../../assets/images/productInfo/inventoryMgrSalesDateRangePicker.png" style="height:100%">
        </mat-card>
      </div>
      <div class="centerText mat-subheading-2" style="margin-top:20px; margin-bottom: 50px">
        <div>Choose exactly which Sales Period</div>
        <div>to base your Recommendations on</div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" style="padding:0px 10px 0px 10px" data-aos="fade-up" data-aos-duration="500">
      <div class="centerText mat-subheading-2">Grow Healthy Inventory</div>
      <div>
        <mat-card style="height:300px">
          <img src="../../../../../assets/images/productInfo/inventoryMgrCardListingHealth.png" style="height:100%">
        </mat-card>
      </div>
      <div class="centerText mat-subheading-2" style="margin-top:20px; margin-bottom:50px; width:407px">
        Optimize and scale your business through robust Inventory Health
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" style="padding:0px 10px 0px 10px" data-aos="fade-left" data-aos-duration="500">
      <div class="centerText mat-subheading-2">Save on Storage</div>
      <div>
        <mat-card style="height:300px">
          <img src="../../../../../assets/images/productInfo/inventoryMgrCardLTSF.png" style="height:100%">
        </mat-card>
      </div>
      <div class="centerText mat-subheading-2" style="margin-top:20px; margin-bottom:50px; width:234px">
        Know when to remove inventory, discount, or sell-through
      </div>
    </div>
  </div>
</section>
<section style="margin-top:30px; margin-left:auto; margin-right:auto; width:90%;">
  <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-md="column">
    <div style="max-width:500px; padding:0px 20px 0px 20px" data-aos="fade-right" data-aos-duration="500">
      <div class="mat-title">
        Deep Dive into the Data
      </div>
      <div>
        Item-level popups give deep insight into historical performance as well as clear calls-to-action.
      </div>
    </div>
    <div style="width:60%; padding:15px 0px 15px 0px;" data-aos="fade-left" data-aos-duration="500">
      <mat-card>
        <img src="../../../../../assets/images/productInfo/inventoryMgrDialogOverlay3.png" style="width:100%"/>
      </mat-card>
    </div>
  </div>
</section>
<section class="backgroundcolor-sky" style="margin-top:30px; margin-left:auto; margin-right:auto;">
  <div style="margin-left:50px; margin-right:50px" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-md="column">
    <div style="max-width:600px; padding:15px 0px 15px 0px;" data-aos="fade-right" data-aos-duration="1000">
      <mat-card>
        <img src="../../../../../assets/images/productInfo/inventoryMgrYoYColumn.png" style="width:100%; max-height:400px"/>
      </mat-card>
    </div>
    <div style="padding:0px 20px 0px 20px" data-aos="fade-left" data-aos-duration="1000">
      <div class="mat-title">
        Our latest addition! Year over year trend analysis
      </div>
      <div>
        See which of your products not only trend up (or down), but also by what average sales factor.
      </div>
    </div>
  </div>
</section>
<section style="margin-top:40px; margin-bottom:40px">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div class="centerText" style="font-size:24px; line-height:30px; width:70%">
      <span style="font-weight:600">Inventory Forecaster</span> will put your business on track for long-term, sustainable growth.
    </div>
    <div style="margin-top:20px"><a mat-raised-button style="padding:10px 20px 10px 20px; font-size:20px" color="primary" href="https://calendly.com/bloomrail/standard" target="_blank">Request a Demo</a></div>
  </div>
</section>
import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

export interface PressRelease {
  releaseDate: Date;
  title: string;
  link: string;
  external: boolean;
}

@Component({
  selector: 'app-newsroom',
  templateUrl: './newsroom.component.html',
  styleUrls: ['./newsroom.component.scss']
})
export class NewsroomComponent implements OnInit {

  pressReleases: PressRelease[];
  displayedColumns: string[] = ['Date', 'Title', 'Link'];

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.seoService.updateSocialTags(
      'bloomRail in the News', 
      'Read more about what bloomRail has been up to in the news.', 
      '/assets/images/logoOnly_green.png', 
      '/press/newsroom');
    
    this.seoService.updateDescription('Read more about what bloomRail has been up to in the news.');

    this.pressReleases = [
      {releaseDate: new Date('2021-08-03T00:00:00'), title: "Lighthouse Labs' 11th accelerator will use a hybrid virtual and in-person model", link: 'https://www.bizjournals.com/richmond/inno/stories/news/2021/08/03/lighthouse-labs-announces-its-11th-cohort.html', external: true},
      {releaseDate: new Date('2021-08-02T00:00:00'), title: 'WATCH NOW: Eight startup businesses chosen for the fall cohort of startup accelerator Lighthouse Labs', link:'https://richmond.com/business/local/watch-now-eight-startup-businesses-chosen-for-the-fall-cohort-of-startup-accelerator-lighthouse-labs/article_23adac34-4e07-58e5-9f44-f057e8ad8270.html', external: true},
      {releaseDate: new Date('2021-08-02T00:00:00'), title: 'Introducing: The Teams of Fall 2021!', link:'https://www.lighthouselabsrva.com/blog/now-announcing-our-11th-cohort', external: true},
      {releaseDate: new Date('2020-11-01T00:00:00'), title: 'How bloomRail founders built software startup through professional experience and a service mindset', link: 'https://www.bizjournals.com/richmond/inno/stories/profiles/2020/11/11/bloomrail-built-on-experience.html', external: true},
      {releaseDate: new Date('2020-10-22T00:00:00'), title: 'bloomRail Launches Site and Debuts Restock Forecaster Tool', link: '/press/articles/2020-10-22-press-release-bloomrail-launch', external: false}
    ];
  }

}

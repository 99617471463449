<section style="width:130%; left: 50%; padding-bottom:18px; position: relative; background: #B9D0AD; border-radius: 0 0 50% 50%; transform: translateX(-50%) rotate(2deg);">
  <div class="homeTopContainer" style="width:100%; left: 50%; position: relative; background: #8ca77d; border-radius: 0 0 50% 50%; transform: translateX(-50%) rotate(-2deg);">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div style="width:70%; margin: 0 auto; z-index:50; margin-top:20px; color:white" fxLayout="row" fxLayoutAlign="space-evenly center"
        fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center" [ngStyle.lt-md]="{'width':'100%'}">
        <div style="margin-left:30px; font-family:'Poppins'; max-width:55%" data-aos="fade-left" data-aos-duration="500">
          <div fxLayout="column" [ngStyle.lt-md]="{'height':'auto'}">
            <div style="margin-bottom:0px; font-size:3vw; line-height:2.75vw; font-weight: 500;" [ngStyle.lt-md]="{'font-size':'24px', 'line-height':'32px'}">
              Save time. Save money. <br/>Save the planet.
            </div>
          </div>
          <div style="font-size:1.25vw; line-height:1.55vw; margin-top:20px" [ngStyle.lt-md]="{'font-size':'16px', 'line-height':'20px', 'margin-top':'10px'}">
            <h1 style="font-size:1.25vw; line-height:1.55vw; margin-top:20px" [ngStyle.lt-md]="{'font-size':'16px', 'line-height':'20px', 'margin-top':'10px'}">bloomRail’s precision Inventory Forecaster helps you improve your operations while reducing your carbon footprint.<br/></h1>
            <div style="margin-top:10px">Improve your business’s sustainability, reduce wasted costs, and increase sales with logistics optimization.</div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" style="max-width:30%; margin:0px 20px 0px 20px" data-aos="fade-right" data-aos-duration="500">
          <div [ngStyle.lt-md]="{'display':'none'}">
            <img src="../../../../assets/images/icons/planet_cityskyline.png" style="height:300px;">
          </div>
        </div>
      </div>
      <div style="z-index:50; margin-top:30px" fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="10px">
        <div data-aos="flip-up" data-aos-duration="500" fxLayout="column" fxLayoutAlign="center center">
          <div>
            <a mat-raised-button href="{{ baseUrlPortal }}/create-account" style="padding:10px 20px 10px 20px; font-size:24px">Start for FREE</a>
          </div>
          <div class="mat-caption" style="font-family:'Poppins'; margin-top:4px; color:white">No credit card required</div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="standardSideMargins" style="font-size:20px; line-height:24px;">
  <div fxLayout="row" fxLayoutAlign="space-evenly top" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
    <div fxLayout="column" fxLayoutAlign="start center" style="width:40%" [ngStyle.lt-md]="{'width':'80%'}" data-aos="fade-right" data-aos-duration="500">
      <img src="../../../../assets/images/icons/restock.png" style="width:300px; height:300px; padding:50px 50px 50px 50px"/>
      <div>
        Never miss a sale, even during your busiest seasons
      </div>
      <div fxFlex></div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start center" style="width:40%; margin-left:20px; margin-right:20px" [ngStyle.lt-md]="{'width':'80%'}" data-aos="fade-up" data-aos-duration="500">
      <img src="../../../../assets/images/icons/container.png" style="width:300px"/>
      <div>
        Save time and money with fewer shipments and reduced storage fees
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start center" style="width:40%" [ngStyle.lt-md]="{'width':'80%'}" data-aos="fade-left" data-aos-duration="500">
      <img src="../../../../assets/images/icons/gears.png" style="width:300px"/>
      <div>
        Use easy-to-read data insights to make the best decisions for your business 
      </div>
      <div fxFlex></div>
    </div>
  </div>
  <div class="centerText">
    <a style="margin-top:20px; padding:10px 20px 10px 20px; font-size:24px" routerLink="/product-info/inventory-forecaster" fragment="solutions" mat-raised-button color="primary">Learn More</a>
  </div>
  <div class="centerText mat-caption" style="margin-top:10px">
    No credit card required to register.
  </div>
</section>
<section class="backgroundcolor-sage" style="margin-top:20px; padding-top:20px; padding-bottom:20px">
  <div class="standardSideMargins centerText">
    <div class="mat-title">Join our Network of Market Leading Brands</div>
    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-top:10px">
      <div style="max-width:20%" data-aos="flip-left" data-aos-duration="500">
        <img src="../../../../assets/images/companyLogos/sunvara.png" style="max-height:120px; max-width:80%">
      </div>
      <div style="max-width:20%" data-aos="flip-up" data-aos-duration="500">
        <img src="../../../../assets/images/companyLogos/boxedUp.png" style="max-width:80%"/>
      </div>
      <div style="max-width:20%" data-aos="flip-down" data-aos-duration="500">
        <img src="../../../../assets/images/companyLogos/spikeball.webp" style="max-width:100%"/>
      </div>
      <div style="max-width:20%" data-aos="flip-up" data-aos-duration="500">
        <img src="../../../../assets/images/companyLogos/viiApparel.png" style="max-width:100%"/>
      </div>
      <div style="max-width:20%" data-aos="flip-right" data-aos-duration="500">
        <img src="../../../../assets/images/companyLogos/wizardPins.png" style="max-width:100%"/>
      </div>
    </div>
  </div>
</section>
<section class="centerDiv" style="margin-top:40px; max-width:80%">
  <div class="mat-headline centerText" style="margin-bottom:40px">Latest Blog Posts</div>
  <div fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="50px" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
    <div class="centerText" fxLayout="row" fxLayoutAlign="center center" fxFlex="33" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center" [ngStyle.lt-md]="{'margin-bottom':'40px'}">
      <div fxLayout="column" fxLayoutAlign="center center" data-aos="fade-right" data-aos-duration="500" style="margin-left:10px; margin-right:10px">
        <a routerLink="/blog/posts/post-20220209-domestic-manufacturing-for-resilient-supply-chain-management">
          <div class="blogPostTitle link" style="margin-bottom:0px">
            Domestic Manufacturing for Resilient Supply Chain Management
          </div>
        </a>
      </div>
      <div data-aos="fade-up" data-aos-duration="500" style="margin-left:10px; margin-right:10px">
        <img src="../../../../assets/images/icons/diagram.png" style="width:150px">
      </div>
    </div>

    <div class="centerText" fxLayout="row" fxLayoutAlign="center center" fxFlex="33" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center" [ngStyle.lt-md]="{'margin-bottom':'40px'}">
      <div fxLayout="column" fxLayoutAlign="center center" data-aos="fade-right" data-aos-duration="500" style="margin-left:10px; margin-right:10px">
        <a routerLink="/blog/posts/post-20220202-environmental-sustainability-is-good-for-the-e-commerce-bottom-line">
          <div class="blogPostTitle link" style="margin-bottom:0px">
            Environmental Sustainability Is Good for the E-Commerce Bottom Line
          </div>
        </a>
      </div>
      <div data-aos="fade-up" data-aos-duration="500" style="margin-left:10px; margin-right:10px">
        <img src="../../../../assets/images/icons/planet_cityskyline.png" style="width:150px">
      </div>
    </div>
    <div class="centerText" fxLayout="row" fxLayoutAlign="center center" fxFlex="33" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
      <div fxLayout="column" fxLayoutAlign="center center" data-aos="fade-left" data-aos-duration="500" style="margin-left:10px; margin-right:10px">
        <a routerLink="/blog/posts/post-20211123-4-low-cost-ecommerce-marketing-strategies">
          <div class="blogPostTitle link" style="margin-bottom:0px">
            4 Low-Cost Ecommerce Marketing Strategies
          </div>
        </a>
      </div>
      <div data-aos="fade-down" data-aos-duration="500" style="margin-left:10px; margin-right:10px">
        <img src="../../../../assets/images/blog/2021/digital-marketing-with-a-megaphone-concept-flat-design.jpg" style="width:150px">
      </div>
    </div>
  </div>
</section>
<section class="backgroundcolor-sky" style="margin-top:40px; padding-top:20px; padding-bottom:30px" data-aos="flip-up" data-aos-duration="1000">
  <div class="centerDiv" style="width:50%" [ngStyle.lt-md]="{'width':'90%'}">
    <div class="mat-title centerText color-primary">Multi-channel Support</div>
    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-top:20px">
      <img src="../../../../assets/images/services/logoAmazon.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
      <img src="../../../../assets/images/services/logoShopify.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
      <img src="../../../../assets/images/services/logoWalmart.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
      <img src="../../../../assets/images/services/logoWoo.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
      <img src="../../../../assets/images/services/logoEbay.png" style="height:50px" [ngStyle.lt-md]="{'height':'25px'}"/>
    </div>
  </div>
</section>
<!-- <section class="centerText" style="margin-top:40px; margin-bottom:40px">
  <div style="color:#004865">
    Want access to industry insights, announcements, and helpful resources?<br/>Sign up here!
  </div>
  <form (ngSubmit)="emailSubscription()">
    <div fxLayout="row" fxLayoutAlign="center center" style="margin-top:10px">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>email address</mat-label>
          <input matInput [formControl]="emailAddress">
          <mat-error *ngIf="emailAddress.invalid">{{ getEmailAddressError() }}</mat-error>
        </mat-form-field>
      </div>
      <div style="margin-bottom:10px">
        <button mat-raised-button type="submit" style="margin-left:10px; margin-top:-20px">Submit</button>
      </div>
    </div>
  </form>
</section> -->
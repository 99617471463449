import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-post20220202',
  templateUrl: './post20220202.component.html',
  styleUrls: ['./post20220202.component.scss']
})
export class Post20220202Component implements OnInit {

  baseUrlPortal: string;

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.baseUrlPortal = environment.BASE_URL_PORTAL;
    
    this.seoService.updateSocialTags(
      'brBlog: Environmental Sustainability Is Good for the E-Commerce Bottom Line', 
      'Environmental sustainability, for decades, may have been a concept that struck fear into the hearts of for-profit businesses concerned with the bottom line...', 
      '/assets/images/icons/planet_cityskyline.png', 
      '/blog/posts/post-20220202-environmental-sustainability-is-good-for-the-e-commerce-bottom-line');
  }

}

<div class="newsMargins">
  <div fxLayout="row" style="width:100%">
    <div [ngStyle.lt-md]="{'display':'none'}"><img src="../../../../../assets/images/bloomRail_Tag_RGB.png" style="width:200px"/></div>
    <div fxFlex [ngStyle.lt-md]="{'display':'none'}"></div>
    <div style="text-align:right" [ngStyle.lt-md]="{'text-align':'center'}"  [ngClass.lt-md]="{'centerDiv':'true'}">
      <div style="font-weight:500">Contact:</div>
      <div>Allen Rogers, Co-founder and CEO</div>
      <div>bloomRail</div>
      <div>(833) 256-7245</div>
      <div><a href="mailto:allen@bloomrail.com">allen@bloomrail.com</a></div>
      <div><a href="https://www.bloomrail.com">www.bloomrail.com</a></div>
    </div>
  </div>
  <div class="centerText" style="margin-top:30px">
    <div style="margin-bottom:10px; font-weight:500">FOR IMMEDIATE RELEASE</div>
    <div style="margin-bottom:10px; font-weight:500">bloomRail Launches Site and Debuts Restock Forecaster Tool</div>
    <div>Richmond-based SaaS Startup will Support and Empower E-commerce Sellers</div>
  </div>
  <div style="margin-top:40px">
    <p><span style="font-weight:500">Richmond, Virginia, October 22, 2020 –</span> Co-founders Allen Rogers and Will Hunt announce the launch of bloomRail, a SaaS company offering e-commerce solutions for businesses and sellers of all sizes. </p>
    <p>bloomRail's inaugural product, the Restock Forecaster, is an intuitive inventory tracking tool for online sellers. The Restock Forecaster features clear calls to action, alerting sellers when they need to order new products. Subsequent launches will include: </p>
    <div>
      <ul>
        <li>A multi-channel inventory management product with source-of-truth data capabilities </li>
        <li>Merchant-fulfilled order management and logistics tools </li>
        <li>PPC advertising automation</li>
      </ul>
    </div>
    <p>"Our focus is on helping sellers build a solid foundation for long-term success in a crowded and fast-paced environment," said Allen Rogers, co-founder and CEO of bloomRail. "We're thrilled to offer our users a sound operational framework and the right tools to succeed."</p>
    <p>Also forthcoming is bloomRail's Business Engine, which will create customized recommendations based on user inputs.</p>
    <p><span style="font-weight:500">The Road to bloomRail</span></p>
    <p>Rogers co-founded bloomRail after spending five years at Amazon, where he was an original member of the Amazon Business marketplace team, specializing in listing tool development and pioneering enterprise B2B integration services. Co-founder and CTO Will Hunt brings over 14 years of experience building custom software. His expertise in critical data management contributed to enterprise-level solutions still in use at the FDA, safeguarding the integrity of our nation's drug approval process.</p>
    <p>Hunt and Rogers share a passion for understanding clients' business challenges and working together to develop robust technical solutions. They have three ambitious goals for bloomRail:</p>
    <div>
      <ol>
        <li>Help every seller lay the tracks for success. </li>
        <li>Equip each with the correct tools to execute and grow.</li>
        <li>Guide them down the path.</li>
      </ol>
    </div>
    <p>"We want every interaction with bloomRail to leave users confident their decisions are data-driven and the right call for their business," said Rogers.</p>
    <p>For more information about bloomRail or to request a demo, visit <a href="https://www.bloomrail.com">www.bloomrail.com</a>.</p>
  </div>
</div>
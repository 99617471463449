import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/service/seo.service';

@Component({
  selector: 'app-post20201010',
  templateUrl: './post20201010.component.html',
  styleUrls: ['./post20201010.component.scss']
})
export class Post20201010Component implements OnInit {

  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.seoService.updateSocialTags(
      'brBlog: Be Helpful and Do Good Work', 
      'In 2014, I landed an opportunity that changed my career - and life - trajectory. Amazon was opening its B2B marketplace...', 
      '/assets/images/bloomRail_Tag_RGB_green.png', 
      '/blog/posts/post-20201010-be-helpful-and-do-good-work');
  }

}

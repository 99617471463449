import { Injectable } from '@angular/core';

import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppService } from '../../service/app.service';

@Injectable({
  providedIn: 'root'
})
export class ServerErrorInterceptorService {

  constructor(private router: Router, private appService: AppService, private snackBar: MatSnackBar) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // retry(1),
      catchError((error: HttpErrorResponse) => {
        if(error.status === 504) {
          this.appService.displaySnackbarError('The server seems to be down; please try again later.');
          return throwError(error);
        } else if(error.status === 400) {
          if(error.error.fieldName) {
            /**
             * This means a fieldName value has been set by the back end, meaning we have a
             * fieldName-specific error. Pass back to the caller to properly handle.
             */
            return throwError(error);
          } else {
            this.displayError(error);
            return throwError(error);
          }
        } else {
          this.displayError(error);
          return throwError(error);
        }
      })
    );
  }

  /**
   * Displays a snack bar element at the bottom of the page when there is a non field-specific
   * error to display.  If message has been returned, display that, otherwise just that there
   * was an unexpected error.
   * 
   * @param error 
   */
  displayError(error: HttpErrorResponse) {
    let errorMessage: string;
    // console.log(error);
    if(error.error.message) {
      errorMessage = error.error.message;
    } else {
      errorMessage = 'Unexpected error (SBSE-01); please try again.';
    }
    this.appService.displaySnackbarError(errorMessage);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Restock } from '../model/restock/restock';
import { FbaInboundShipment } from '../model/restock/fba-inbound-shipment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TopSellerInfo } from '../model/dashboard/top-seller-info';
import { ContactUs } from '../model/general/contact-us';
import { Overstock } from '../model/inventory/overstock';
import { InventoryHealth } from '../model/dashboard/inventory-health';

@Injectable({
  providedIn: 'root'
})
export class BloomRestService {

  private apiUrlBase: string;
 
  constructor(private http: HttpClient) {
    this.apiUrlBase = '/api';
  }
 
  // Restock methods - BEGIN
  public getRestockData(merchantId: string): Observable<Restock[]> {
    let apiUrl = this.apiUrlBase + '/restock/listRestockReport?merchantId=' + merchantId + '&instockAlert=low_stock,out_of_stock';
    return this.http.get<Restock[]>(apiUrl).pipe(map(data => data));
  }

  public getWorkingOrders(merchantId: string): Observable<FbaInboundShipment[]> {
    let apiUrl = this.apiUrlBase + '/restock/getWorkingOrders?merchantId=' + merchantId;
    return this.http.get<FbaInboundShipment[]>(apiUrl).pipe(map(data => data));
  }

  public getRestockDataSourceDates(merchantId: string): Observable<any> {
    let apiUrl = this.apiUrlBase + '/restock/getRestockDataSourceDates?merchantId=' + merchantId;
    return this.http.get<any>(apiUrl).pipe(map(data => data));
  }
  // Restock methods - END

  // Overstock methods - BEGIN
  public getOverstockData(merchantId: string): Observable<Overstock[]> {
    let apiUrl = this.apiUrlBase + '/overstock/listOverstockReport?merchantId=' + merchantId;
    return this.http.get<Overstock[]>(apiUrl).pipe(map(data => data));
  }
  // Overstock methods - END

  // Dashboard methods - BEGIN
  public getSalesChartData(merchantId: string): Observable<any> {
    let apiUrl = this.apiUrlBase + '/dashboard/getSalesChartData?merchantId=' + merchantId;
    return this.http.get<any>(apiUrl).pipe(map(data=>data));
  }

  public getOverstockItemsForDashboard(merchantId: string): Observable<Overstock[]> {
    let apiUrl = this.apiUrlBase + '/dashboard/getOverstockDataForDashboard?merchantId=' + merchantId;
    return this.http.get<Overstock[]>(apiUrl).pipe(map(data=>data));
  }

  public getRestockItemsForDashboard(merchantId: string): Observable<Restock[]> {
    let apiUrl = this.apiUrlBase + "/dashboard/getRestockDataForDashboard?merchantId=" + merchantId + '&instockAlert=low_stock,out_of_stock';
    return this.http.get<Restock[]>(apiUrl).pipe(map(data => data)); 
  }

  public getInventoryHealthForDashboard(merchantId: string): Observable<InventoryHealth> {
    let apiUrl = this.apiUrlBase + "/dashboard/getInventoryHealthForDashboard?merchantId=" + merchantId;
    return this.http.get<InventoryHealth>(apiUrl).pipe(map(data => data));
  }
  // Dasboard methods - BEGIN

  public sendContactUsEmail(data: ContactUs) {
    return this.http.post<any>(this.apiUrlBase + '/contact/contactUs', data).pipe();
  }

  public addNewsletterSubscription(emailAddress: string) {
    return this.http.post<any>(this.apiUrlBase + '/contact/addMailingListNewsletter', emailAddress).pipe();
  }

}
